import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, FunctionField, EditButton,
    ReferenceField, TextInput, FormDataConsumer, ReferenceArrayInput,
    AutocompleteArrayInput, SelectInput, DateField, useEditContext, useGetOne,
    ReferenceInput, AutocompleteInput
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";
import SelectUserByEmailInput from "../components/form/SelectUserByEmailInput";

// Libs
import moment from "moment";
import jsonExport from 'jsonexport/dist';
import * as XLSX from 'xlsx';
import _ from 'lodash';

// Entity
const entity = "promoters";

const exporter = async (records, _getMany, dataProvider) => {

    // gather relationship ids
    const userIds = records.map((record) => record.user ?? null);
    const u_userIds = _.uniq(userIds);

    console.log("userIds", u_userIds);

    const users = await dataProvider.getMany('users', { ids: u_userIds });
    const data = [];

    // gather orgComanies or venues which the promoters belongs to
    const orgCompanyIds = records.map((record) => record.organisationCompanies ?? null);
    const venueIds = records.map((record) => record.venues ?? null);

    const u_orgCompanyIds = _.uniq(orgCompanyIds.flat()).filter((v) => v !== null);
    const u_venueIds = _.uniq(venueIds.flat()).filter((v) => v !== null);

    const orgCompanies = await dataProvider.getMany('organisationCompanies', { ids: u_orgCompanyIds });
    const venues = await dataProvider.getMany('venues', { ids: u_venueIds });

    for (const record of records) {
        const userIndex = users.data && users.data.length > 0 ? users.data.findIndex(v => v.id === record.user) : -1;
        data.push({
            // "Promoter ID": record.id,
            "User": userIndex > -1 ? `${users.data[userIndex].name} ${users.data[userIndex].surname}` : '-',
            "Mobile": userIndex > -1 ? users.data[userIndex].mobile : '-',
            "Belongs To": record.organisationCompanies && record.organisationCompanies.length > 0 ? record.organisationCompanies.map((v) => {
                const orgCompanyIndex = orgCompanies.data.findIndex(c => c.id === v);
                return orgCompanyIndex > -1 ? orgCompanies.data[orgCompanyIndex].name.concat(' (Org. Company)') : '-';
            }
            ) : record.venues && record.venues.length > 0 ? record.venues.map((v) => {
                const venueIndex = venues.data.findIndex(c => c.id === v);
                return venueIndex > -1 ? venues.data[venueIndex].name.concat(' (Venue)') : '-';
            }) : '-',
            // "Created At": moment(record.createdate).format('LLLL').toString()
        });
    }

    return jsonExport(data, (err, csvString) => {
        const arrayOfArrayCsv = csvString.split("\n").map((row) => {
            return row.split(",")
        });

        const wb = XLSX.utils.book_new();
        const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
        XLSX.utils.book_append_sheet(wb, newWs);

        return XLSX.writeFileXLSX(wb, `gisekibris-promoters-${moment().format('LL-LT')}.xlsx`);
    });
}

const filters = [
    <ReferenceInput source="organisationCompanies" reference="organisationCompanies" label="Org. Company Filter" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
        <AutocompleteInput optionText={"name"} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Company Name" style={{ minWidth: 200 }} />
    </ReferenceInput>,
    <ReferenceInput source="venues" reference="venues" label="Venue Filter" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
        <AutocompleteInput optionText={"name"} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Venue Name" style={{ minWidth: 150 }} />
    </ReferenceInput>,
]

export const PromoterList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Promoters" filters={filters}
        exporter={exporter}  // TODO: Exporter as per spec, will need to fetch dynamic ones
    >
        <Datagrid>
            <ReferenceField source="user" reference="users" label="User" link={false}>
                <FunctionField render={(r) => `${r.name} ${r.surname}`} />
            </ReferenceField>
            <ReferenceField source="user" reference="users" label="Mobile" link={false}>
                <FunctionField render={(r) => `${r.mobile}`} />
            </ReferenceField>
            <FunctionField source="organisationCompanies" label="Belongs To" render={(r) => {
                if (r.organisationCompanies && r.organisationCompanies.length > 0) {
                    return r.organisationCompanies.map((v, i) => {
                        return <DynamicCell key={i} reference={"organisationCompanies"} id={v} field={"name"} suffix={" (Org. Company)"} />
                    });
                } else if (r.venues && r.venues.length > 0) {
                    return r.venues.map((v, i) => {
                        return <DynamicCell key={i} reference={"venues"} id={v} field={"name"} suffix={" (Venue)"} />
                    });
                }
            }} />
            <DateField source="createdate" label="Created On" />
            <EditButton />
        </Datagrid>
    </List>
);

export const PromoterCreate = (props) => (
    <Create title="New" {...props} redirect={"list"}>
        <SimpleForm redirect={`/${entity}`}>

            <h2>User Selection</h2>
            <SelectUserByEmailInput source={"user"} required={true} />

            <h2>Details</h2>
            {/* Radio Button to select if the promotor belongs to an org. company or a venue */}
            <SelectInput source="promoterType" label="Promoter Type" value={'organisationCompany'} choices={[
                { id: 'organisationCompany', name: 'Organisation Company' },
                { id: 'venue', name: 'Venue' },
            ]} fullWidth defaultValue={"organisationCompany"} emptyValue={'organisationCompany'} emptyText={"Please Select"} />

            <FormDataConsumer>
                {({ formData }) => {
                    if (formData && formData.promoterType === 'organisationCompany') {
                        return (
                            <ReferenceArrayInput source="organisationCompanies" reference="organisationCompanies" label={"Organisation Company"} defaultValue={null} sort={{
                                field: 'name',
                                order: 'ASC'
                            }}>
                                <AutocompleteArrayInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} defaultValue={null} clearOnBlur={false} />
                            </ReferenceArrayInput>
                        )
                    }
                }}
            </FormDataConsumer>

            <FormDataConsumer>
                {({ formData }) => {
                    if (formData && formData.promoterType === 'venue') {
                        return (
                            <ReferenceArrayInput source="venues" reference="venues" label={"Venue"} defaultValue={null} sort={{ field: 'name', order: 'ASC' }}>
                                <AutocompleteArrayInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} defaultValue={null} clearOnBlur={false} />
                            </ReferenceArrayInput>
                        )
                    }
                }}
            </FormDataConsumer>

            <TextInput source={'notes'} label={"Notes"} fullWidth multiline rows={5} defaultValue={null} placeholder="Any notes related to the promoter can go here.." />

        </SimpleForm>
    </Create>
);


export const PromoterEdit = (props) => (
    <Edit title="Edit" {...props} redirect={"list"}>
        <SimpleForm redirect={`/${entity}`}>
            <EditForm />
        </SimpleForm>
    </Edit>
);

const EditForm = (props) => {
    const { record, isLoading } = useEditContext();
    if (isLoading) return null;

    return (
        <React.Fragment>
            <h2>User</h2>

            <SelectUserByEmailInput source={"user"} required={true} record={record} />

            <h2>Details</h2>
            {/* Radio Button to select if the promotor belongs to an org. company or a venue */}
            {/* <SelectInput source="promoterType" label="Type" value={'organisationCompany'} choices={[
                { id: 'organisationCompany', name: 'Organisation Company' },
                { id: 'venue', name: 'Venue' },
            ]} fullWidth defaultValue={"organisationCompany"} emptyValue={'organisationCompany'} emptyText={"Please Select"} /> */}

            {/* <FormDataConsumer>
                {({ formData }) => {
                    if (formData && formData.promoterType === 'organisationCompany') {
                        return (
                            <ReferenceArrayInput source="organisationCompanies" reference="organisationCompanies" label={"Organisation Company"} defaultValue={null} sort={{
                                field: 'name',
                                order: 'ASC'
                            }}>
                                <AutocompleteArrayInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} defaultValue={null} clearOnBlur={false} />
                            </ReferenceArrayInput>
                        )
                    }
                }}
            </FormDataConsumer>

            <FormDataConsumer>
                {({ formData }) => {
                    if (formData && formData.promoterType === 'venue') {
                        return (
                            <ReferenceArrayInput source="venues" reference="venues" label={"Venue"} defaultValue={null} sort={{ field: 'name', order: 'ASC' }}>
                                <AutocompleteArrayInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} defaultValue={null} clearOnBlur={false} />
                            </ReferenceArrayInput>
                        )
                    }
                }}
            </FormDataConsumer> */}

            <ReferenceArrayInput source="organisationCompanies" valu reference="organisationCompanies" label={"Organisation Company"} defaultValue={null} sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteArrayInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} defaultValue={null} clearOnBlur={false} />
            </ReferenceArrayInput>

            <ReferenceArrayInput source="venues" reference="venues" label={"Venue"} defaultValue={null} sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteArrayInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} defaultValue={null} clearOnBlur={false} />
            </ReferenceArrayInput>

            <TextInput source={'notes'} label={"Notes"} fullWidth multiline rows={5} defaultValue={null} placeholder="Any notes related to the promoter can go here.." />

        </React.Fragment>
    )
}

const DynamicCell = ({ reference, id, field, suffix }) => {
    const { data, isLoading, error } = useGetOne(
        reference,
        { id }
    );

    if (isLoading) return <span>Loading...</span>
    if (error) return <span>Error</span>

    if (field.indexOf('+') > -1) {
        let fields = field.split('+');
        return <span>{data[fields[0]]} {data[fields[1]]}</span>
    }
    else return <span>{data[field]}{suffix}</span>
}