import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, 
    ReferenceInput, SelectInput, TranslatableInputs, ReferenceField } from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Entity
const entity = "subcategories";

export const SubCategoryList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Sub Categories" exporter={false}>
        <Datagrid>
            <TextField source="name.en" label={"Name"} />
            <ReferenceField source="category" reference="categories">
                <TextField source="name.en" />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
);

export const SubCategoryEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <TextInput source="id" label={"ID"} disabled />
            <ReferenceInput source="category" reference="categories">
                <SelectInput optionText={"name.en"} />
            </ReferenceInput>
            <TranslatableInputs locales={['tr', 'en']}>
                <TextInput source="name" label={"Label"} fullWidth />
            </TranslatableInputs>
        </SimpleForm>
    </Edit>
);

export const SubCategoryCreate = (props) => (
    <Create title="New" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <TextInput source="id" label={"ID"} />
            <ReferenceInput source="category" reference="categories">
                <SelectInput optionText={"name.en"} fullWidth />
            </ReferenceInput>
            <TranslatableInputs locales={['tr', 'en']}>
                <TextInput source="name" label={"Label"} fullWidth />
            </TranslatableInputs>
        </SimpleForm>
    </Create>
);
