import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, TranslatableInputs, FunctionField } from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Libs
import moment from 'moment';

// Entity
const entity = "deleteUserRequests";

export const DeleteUserRequestList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} exporter={false} sort={{ field: 'createdate', order: 'DESC' }} perPage={50} title={"Delete Requests"}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="uid" label={"ID"} />
            <FunctionField source="namesurname" label={"Name"} render={r => {
                return `${r.name} ${r.surname}`;
            }} />
            <FunctionField source="reason" label={"Reason"} render={(r) => {
                // "1": "I have another account",
                // "2": "I have privacy concerns",
                // "3": "I have inactive or unused account",
                // "4": "I have another reason"


                switch (r.reason) {
                    case "1":
                    case 1:
                        return "I have another account";
                    case "2":
                    case 2:
                        return "I have privacy concerns";
                    case "3":
                    case 3:
                        return "I have inactive or unused account";
                    case "4":
                    case 4:
                        return "I have another reason";
                    default:
                        return "Unknown";
                }

            }} />
            <TextField source="email" label={"Email"} />
            <FunctionField source="createdate" label={"Requested At"} render={r => moment(r.createdate, 'x').format('LLLL')} />
            {/* <EditButton /> */}
            {/* <DeleteButton basePath={`/${entity}`} /> */}
        </Datagrid>
    </List>
);