import * as React from "react";
import {
    List, Datagrid, TextField, FunctionField, BooleanField, ReferenceField
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Libs
import moment from "moment";

// Entity
const entity = "verificationLogs";

export const LogList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} noCreate />} sort={{field: 'createdate', order: 'DESC'}}>
        <Datagrid>
            <FunctionField source="createdate" render={r => moment(r.createdate).format('LL, LT')} />
            <FunctionField source="id" render={r => "Verification Log"} label="Type" />
            <TextField source="message" />
            <BooleanField source="success" label="Is Succesful" />
            <ReferenceField source="uid" reference="users">
                <FunctionField source="name" render={(r) => `${r.name} ${r.surname}`} />
            </ReferenceField>
        </Datagrid>
    </List>
);
