import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput,
    required, ImageInput, ImageField, DateInput, FunctionField,
    BooleanInput, TranslatableInputs, AutocompleteArrayInput, useEditContext,
    SelectArrayInput, ReferenceArrayInput
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

// Libs
import moment from "moment";
import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';

// Firebase
import { db } from "../database/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

// Components
import EmptyList from "../components/misc/EmptyList";
import InputRow from "../components/form/InputRow";

// Entity
const entity = "groupEvents";

export const GroupEventList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Group Events" exporter={false}>
        <Datagrid>
            <TextField source="name" label={"Name"} />
            <FunctionField source="events" render={(r) => r.events ? r.events.length : 'N/A'} label={"# of Events"} />
            <EditButton />
            {/* <DeleteButton /> */}
        </Datagrid>
    </List>
);


export const GroupEventCreate = (props) => (
    <Create title="New" {...props} redirect={"list"}>
        <SimpleForm redirect={"list"}>

            <h3>Basic Details</h3>
            <TextInput source="name" fullWidth label="Name" validate={required()} />
            <InputRow>
                <DateInput source="startDate" label="Start Date" validate={required()} fullWidth />
                <DateInput source="endDate" label="End Date" validate={required()} fullWidth />
            </InputRow>
            
            <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                <RichTextInput source={"description"} label="Description" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />
            </TranslatableInputs>

            <h3>Actions</h3>
            <BooleanInput source="hasMultipleVenues" label="Does this group event happen in multiple venues?" helperText="Only switch to on (true) if there are multiple venues involved." />

            <h3>Media</h3>
            <TextInput source="videoURL" label={"Video ID (Youtube ID)"} fullWidth defaultValue={null} />
            <ImageInput source="banner" label="Banner Image" accept="image/*" validate={required()} defaultValue={null}>
                <ImageField source="src" title="title" />
            </ImageInput>

            <h3>Events</h3>
            <UpcomingEventsSelectInput />

        </SimpleForm>
    </Create>
);

export const GroupEventEdit = (props) => (
    <Edit title="Edit" {...props} redirect={"list"}>
        <SimpleForm redirect={"list"}>
            <EditForm />
        </SimpleForm>
    </Edit>
);
const EditForm = () => {
    const { record, isLoading } = useEditContext();
    if (isLoading) return null;

    return (
        <React.Fragment>
            <h3>Basic Details</h3>
            <TextInput source="name" fullWidth label="Name" validate={required()} />
            <InputRow>
                <DateInput source="startDate" label="Start Date" validate={required()} fullWidth />
                <DateInput source="endDate" label="End Date" validate={required()} fullWidth />
            </InputRow>
            
            <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                <RichTextInput source={"description"} label="Description" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />
            </TranslatableInputs>

            <h3>Actions</h3>
            <BooleanInput source="hasMultipleVenues" label="Does this group event happen in multiple venues?" helperText="Only switch to on (true) if there are multiple venues involved." />

            <h3>Media</h3>
            <TextInput source="videoURL" label={"Video ID (Youtube ID)"} fullWidth />
            <ImageInput source="banner" label="Banner Image" accept="image/*" validate={required()} defaultValue={null}>
                <ImageField source="src" title="title" />
            </ImageInput>

            <h3>Events</h3>
            <UpcomingEventsSelectInput record={record} />

            <h3>Homepage Display Categories</h3>                
            <span>
                <small>The categories that this group event will be displayed on the website and application homepage.</small>
            </span>
            {/* Home Page Display Categories */}
            {/* A referenceArrayInput of categories */}
            <ReferenceArrayInput source="homeCategories" reference="categories" defaultValue={[]}>
                <SelectArrayInput optionText={"name.en"} fullWidth label="Select Categories" defaultValue={[]} />
            </ReferenceArrayInput>

        </React.Fragment>
    )
}

const UpcomingEventsSelectInput = ({ record }) => {
    const { setValue } = useFormContext();
    const [isLoading, setIsLoading] = React.useState(true);
    const [events, setEvents] = React.useState([]);
    const [preSelectedEvents, setPreSelectedEvents] = React.useState([]);

    React.useEffect(() => {
        const fetchAllChoices = async () => {
            const q = query(collection(db, "events-v2"),
                where("startdate", ">=", new Date()),
                where("isActive", "==", true)
            );
            const querySnapshot = await getDocs(q);
            let data = [];
            querySnapshot.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            setEvents(data);
        };

        const fetchRecordEvents = async () => {
            if (record && record.events && record.events.length > 0) {
                const q = query(collection(db, "events"),
                    where("id", "in", record.events)
                );
                const querySnapshot = await getDocs(q);
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push({ id: doc.id, ...doc.data() });
                });
                setPreSelectedEvents(data);
            }
        };

        (async () => {
            await fetchAllChoices();
            await fetchRecordEvents();
            setIsLoading(false);
        })();
    }, [record]);

    React.useEffect(() => {
        if (!isLoading && record && record.events) {
            setValue("events", record.events);
        }
    }, [isLoading, record, setValue]);

    if (isLoading) return null;

    const allChoices = [...events, ...preSelectedEvents].filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.id === value.id
        ))
    );

    return (
        <AutocompleteArrayInput
            optionText={(r) => `${r.name} (${moment(r.startdate.toDate()).format('LL, LT')})`}
            optionValue="id"
            fullWidth
            label="Select Events"
            clearOnBlur={false}
            choices={allChoices}
            source="events"
        />
    );
};
