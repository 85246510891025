import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, DateField, 
    TranslatableInputs, ImageInput, ImageField } from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Entity
const entity = "heros";

export const HeroList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} noCreate />} hasCreate={false} exporter={false}>
        <Datagrid>
            <TextField source="id" label={"ID"} />
            <DateField source="lastupdate" label={"Last Updated"} />
            <EditButton />
        </Datagrid>
    </List>
);

export const HeroEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={`/${entity}`}>

            <TextInput source="id" label={"ID"} disabled />
            <TranslatableInputs locales={['tr', 'en']}>
                <TextInput source="firstline" label="First Line" fullWidth />
                <TextInput source="secondline" label="Second Line" fullWidth />
                <TextInput source="button.label" label={"Button Label"} fullWidth />
            </TranslatableInputs>

            <TextInput source="button.link" label={"Button Link"} fullWidth />
            <ImageInput source="banner" label="Event Banner" accept="image/*" defaultValue={null}>
                <ImageField source="src" title="banner" />
            </ImageInput>

        </SimpleForm>
    </Edit>
);

export const HeroCreate = (props) => (
    <Create title="New" {...props}>
        <SimpleForm redirect={`/${entity}`}>

            <TextInput source="id" label={"ID"} />
            <TranslatableInputs locales={['tr', 'en']}>
                <TextInput source="firstline" label="First Line" fullWidth />
                <TextInput source="secondline" label="Second Line" fullWidth />
                <TextInput source="button.label" label={"Button Label"} fullWidth />
            </TranslatableInputs>

            <TextInput source="button.link" label={"Button Link"} fullWidth />
            <ImageInput source="banner" label="Event Banner" accept="image/*" defaultValue={null}>
                <ImageField source="src" title="banner" />
            </ImageInput>

        </SimpleForm>
    </Create>
);
