import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, DeleteButton, TextInput, NumberField, NumberInput,
    required, BooleanInput, ImageInput, ImageField, ReferenceInput, SelectInput, FormDataConsumer, AutocompleteInput
} from 'react-admin';

// Libs
import moment from "moment";

// Components
import EmptyList from "../components/misc/EmptyList";
import InputRow from '../components/form/InputRow';

// Entity
const entity = "homeSections";

export const HomeSectionList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Home Sections" sort={{ field: 'order', order: 'ASC' }}>
        <Datagrid>
            <TextField source="title.en" label={"Title (English)"} />
            <NumberField source="order" label={"Order"} />
            <EditButton />
            <DeleteButton basePath={`/${entity}`} />
        </Datagrid>
    </List>
);

export const HomeSectionEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <h3>Main Details</h3>
            <NumberInput source="order" label={"Order"} validate={required()} fullWidth />
            <TextInput source="title.tr" label={"Title TR"} validate={required()} fullWidth />
            <TextInput source="title.en" label={"Title EN"} validate={required()} fullWidth />
            {/* <RichTextInput source="description.tr" validate={required()} fullWidth label={"Description TR"} />
            <RichTextInput source="description.en" validate={required()} fullWidth label={"Description EN"} /> */}
            <ImageInput source="banner" label="Banner" accept="image/*" defaultValue={null}>
                <ImageField source="src" title="title" />
            </ImageInput>

            <h3>Categories</h3>
            <InputRow>
                <ReferenceInput source="category" reference="categories">
                    <SelectInput optionText={"name.en"} fullWidth />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        let filter = formData.category && formData.category.length > 0 ? { category: formData.category } : { category: 'dummy' };
                        return (
                            <ReferenceInput source="subcategory" reference="subcategories" label={"Sub Category"} filter={filter}>
                                <SelectInput optionText="name.en" fullWidth />
                            </ReferenceInput>
                        )
                    }}
                </FormDataConsumer>
            </InputRow>

            <h3>Content</h3>
            <BooleanInput label="Show upcoming events of the category?" source="fetchUpcoming" />

            <h3>See All Details</h3>
            <BooleanInput label="Show See All Button?" source="seeAll.isEnabled" defaultValue={false} defaultChecked={false} />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return formData.seeAll && formData.seeAll.isEnabled && (
                        <React.Fragment>
                            <div style={{
                                width: '100%',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                display: 'flex'
                            }}>
                                <TextInput source="seeAll.title.tr" label={"See All Title Turkish"} fullWidth style={{ marginRight: 10 }} />
                                <TextInput source="seeAll.title.en" label={"See All Title English"} fullWidth />
                            </div>
                            <SelectInput source="seeAll.link.type" label={"See All Link"}
                                // could be Group Event (groupEvent), Venue (venue) or Organisation Company (organisationCompany)
                                fullWidth
                                choices={[
                                    { id: 'groupEvent', name: 'Group Event' },
                                    { id: 'venue', name: 'Venue' },
                                    { id: 'organisationCompany', name: 'Organisation Company' }
                                ]}
                            />


                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    if (formData && formData.seeAll && formData.seeAll.link && formData.seeAll.link.type) {
                                        if (formData.seeAll.link.type === 'groupEvent') {
                                            return (
                                                <ReferenceInput source="seeAll.link.id" reference="groupEvents" label="Group Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Group Event Name" fullWidth />
                                                </ReferenceInput>
                                            )
                                        }
                                        else if (formData.seeAll.link.type === 'venue') {
                                            return (
                                                <ReferenceInput source="seeAll.link.id" reference="venues" label="Venue Filter" sort={{ field: 'name', order: 'ASC' }}>
                                                    <AutocompleteInput optionText={r => `${r.name}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Venue Name" fullWidth />
                                                </ReferenceInput>
                                            )
                                        }
                                        else if (formData.seeAll.link.type === 'organisationCompany') {
                                            return (
                                                <ReferenceInput source="seeAll.link.id" reference="organisationCompanies" label="Organisation Company Filter" sort={{ field: 'name', order: 'ASC' }}>
                                                    <AutocompleteInput optionText={r => `${r.name}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Organisation Company Name" fullWidth />
                                                </ReferenceInput>
                                            )
                                        }
                                    }
                                }}
                            </FormDataConsumer>

                        </React.Fragment>

                    )
                }}
            </FormDataConsumer>

            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return (formData.fetchUpcoming === false) && (
                        <React.Fragment>

                            <h3>Design</h3>
                            <BooleanInput label="Use carousel design?" source="isCarousel" />

                            <h3>Select Events</h3>

                            <h3>Event 1</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>

                                <ReferenceInput source="events.event1.id" reference="events-v2" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event1.id) && (
                                            <ReferenceInput source="events.event1.groupid" reference="groupEvents" perPage={50}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>

                            <h3>Event 2</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                                <ReferenceInput source="events.event2.id" reference="events-v2" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event2.id) && (
                                            <ReferenceInput source="events.event2.groupid" reference="groupEvents" perPage={50}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>

                            <h3>Event 3</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                                <ReferenceInput source="events.event3.id" reference="events-v2" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event3.id) && (
                                            <ReferenceInput source="events.event3.groupid" reference="groupEvents" perPage={50}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>

                            <h3>Event 4</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                                <ReferenceInput source="events.event4.id" reference="events-v2" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event4.id) && (
                                            <ReferenceInput source="events.event4.groupid" reference="groupEvents" perPage={50}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>
                        </React.Fragment>
                    )
                }}
            </FormDataConsumer>

        </SimpleForm>
    </Edit>
);

export const HomeSectionCreate = (props) => (
    <Create title="New" {...props} redirect={"list"}>
        <SimpleForm redirect={`/${entity}`}>
            <h3>Main Details</h3>
            <NumberInput source="order" label={"Order"} validate={required()} fullWidth />
            <TextInput source="title.tr" label={"Title TR"} validate={required()} fullWidth />
            <TextInput source="title.en" label={"Title EN"} validate={required()} fullWidth />
            {/* <RichTextInput source="description.tr" validate={required()} fullWidth label={"Description TR"} />
            <RichTextInput source="description.en" validate={required()} fullWidth label={"Description EN"} /> */}
            <ImageInput source="banner" label="Banner" accept="image/*" defaultValue={null}>
                <ImageField source="src" title="title" />
            </ImageInput>

            <h3>Categories</h3>
            <InputRow>
                <ReferenceInput source="category" reference="categories" defaultValue={null}>
                    <SelectInput optionText={"name.en"} fullWidth defaultValue={null} />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        let filter = formData.category && formData.category.length > 0 ? { category: formData.category } : { category: 'dummy' };
                        return (
                            <ReferenceInput source="subcategory" reference="subcategories" label={"Sub Category"} filter={filter} defaultValue={null}>
                                <SelectInput optionText="name.en" fullWidth defaultValue={null} />
                            </ReferenceInput>
                        )
                    }}
                </FormDataConsumer>
            </InputRow>

            <h3>Content</h3>
            <BooleanInput label="Show upcoming events of the category?" source="fetchUpcoming" defaultValue={true} defaultChecked />

              <h3>See All Details</h3>
            <BooleanInput label="Show See All Button?" source="seeAll.isEnabled" defaultValue={false} defaultChecked={false} />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return formData.seeAll && formData.seeAll.isEnabled && (
                        <React.Fragment>
                            <div style={{
                                width: '100%',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                display: 'flex'
                            }}>
                                <TextInput source="seeAll.title.tr" label={"See All Title Turkish"} fullWidth style={{ marginRight: 10 }} />
                                <TextInput source="seeAll.title.en" label={"See All Title English"} fullWidth />
                            </div>
                            <SelectInput source="seeAll.link.type" label={"See All Link"}
                                // could be Group Event (groupEvent), Venue (venue) or Organisation Company (organisationCompany)
                                fullWidth
                                choices={[
                                    { id: 'groupEvent', name: 'Group Event' },
                                    { id: 'venue', name: 'Venue' },
                                    { id: 'organisationCompany', name: 'Organisation Company' }
                                ]}
                            />


                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    if (formData && formData.seeAll && formData.seeAll.link && formData.seeAll.link.type) {
                                        if (formData.seeAll.link.type === 'groupEvent') {
                                            return (
                                                <ReferenceInput source="seeAll.link.id" reference="groupEvents" label="Group Event Filter" sort={{ field: 'name', order: 'ASC' }}>
                                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Group Event Name" fullWidth />
                                                </ReferenceInput>
                                            )
                                        }
                                        else if (formData.seeAll.link.type === 'venue') {
                                            return (
                                                <ReferenceInput source="seeAll.link.id" reference="venues" label="Venue Filter" sort={{ field: 'name', order: 'ASC' }}>
                                                    <AutocompleteInput optionText={r => `${r.name}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Venue Name" fullWidth />
                                                </ReferenceInput>
                                            )
                                        }
                                        else if (formData.seeAll.link.type === 'organisationCompany') {
                                            return (
                                                <ReferenceInput source="seeAll.link.id" reference="organisationCompanies" label="Organisation Company Filter" sort={{ field: 'name', order: 'ASC' }}>
                                                    <AutocompleteInput optionText={r => `${r.name}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Organisation Company Name" fullWidth />
                                                </ReferenceInput>
                                            )
                                        }
                                    }
                                }}
                            </FormDataConsumer>

                        </React.Fragment>

                    )
                }}
            </FormDataConsumer>


            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return formData.fetchUpcoming === false && (
                        <React.Fragment>

                            <h3>Design</h3>
                            <BooleanInput label="Use carousel design?" source="isCarousel" defaultValue={false} />

                            <h3>Select Events</h3>

                            <h3>Event 1</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                                <ReferenceInput source="events.event1.id" reference="events-v2" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }} defaultValue={null}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event1.id) && (
                                            <ReferenceInput source="events.event1.groupid" reference="groupEvents" perPage={50} defaultValue={null}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" defaultValue={null} />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>

                            <h3>Event 2</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                                <ReferenceInput source="events.event2.id" reference="events-v2" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }} defaultValue={null}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth defaultValue={null} />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event2.id) && (
                                            <ReferenceInput source="events.event2.groupid" reference="groupEvents" perPage={50} defaultValue={null}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" defaultValue={null} />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>

                            <h3>Event 3</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                                <ReferenceInput source="events.event3.id" reference="events-v2" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }} defaultValue={null}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth defaultValue={null} />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event3.id) && (
                                            <ReferenceInput source="events.event3.groupid" reference="groupEvents" perPage={50} defaultValue={null}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" defaultValue={null} />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>

                            <h3>Event 4</h3>
                            <span style={{ fontSize: 12 }}>Only an event or a group event should be selected for each row.</span>
                            <InputRow>
                                <ReferenceInput source="events.event4.id" reference="events-v2" filter={{ hasSessions: false }} label="Event Filter" sort={{ field: 'name', order: 'ASC' }} defaultValue={null}>
                                    <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" fullWidth defaultValue={null} />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (formData.events && !formData.events.event4.id) && (
                                            <ReferenceInput source="events.event4.groupid" reference="groupEvents" perPage={50} defaultValue={null}>
                                                <SelectInput optionText="name" fullWidth label="Select Group Event" defaultValue={null} />
                                            </ReferenceInput>
                                        )
                                    }}
                                </FormDataConsumer>
                            </InputRow>
                        </React.Fragment>
                    )
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);
