import moment from "moment";
import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton,
    ReferenceField, TextInput, FunctionField, DateField, useEditContext
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";
import SelectUserByEmailInput from "../components/form/SelectUserByEmailInput";

// Entity
const entity = "affiliates";

const filters = [
    <TextInput source="code" alwaysOn placeholder="AF Code" label="Search AF Code" autoComplete="off" />,
]

export const AffiliatesList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Affiliates" exporter={false} filters={filters}>
        <Datagrid>
            <TextField source="name" label={"Name"} />
            <TextField source="code" label={"Affiliate Code"} />
            <DateField source="createdate" label={"Created"} />
            <EditButton />
        </Datagrid>
    </List>
);


export const AffiliatesCreate = (props) => {
    let code = "AF" + moment().format('x')
    return (
        <Create title="Create" {...props} redirect={"list"}>
            <SimpleForm redirect={`/${entity}`}>

                <h3>Affiliate Details</h3>
                <TextInput source="name" fullWidth />
                <TextInput source="code" defaultValue={code} value={code} fullWidth disabled />

                <h3>User</h3>
                <SelectUserByEmailInput source={"user"} required={true} />

                <div style={{ visibility: 'hidden' }}>
                    <TextInput source="id" fullWidth disabled defaultValue={code} value={code} />
                </div>
            </SimpleForm>
        </Create>
    );
}

export const AffiliatesEdit = (props) => {
    return (
        <Edit title="Edit" {...props}>
            <SimpleForm redirect={`/${entity}`}>
                <EditForm />
            </SimpleForm>
        </Edit>
    );
}

const EditForm = (props) => {
    const { record, isLoading } = useEditContext();
    if (isLoading) return null;

    return (
        <React.Fragment>
            
            <h3>Affiliate Details</h3>
            <TextInput source="name" fullWidth />
            <TextInput source="code" fullWidth disabled />

            <h3>User</h3>
            <SelectUserByEmailInput source={"user"} required={false} record={record} />
            
        </React.Fragment>
    )
}