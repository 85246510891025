import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput,
    BooleanInput, SelectInput, RadioButtonGroupInput, FunctionField, TranslatableInputs
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Entity
const entity = "categories";

export const CategoryList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} exporter={false} sort={{
        field: 'order',
        order: 'ASC'
    }}>
        <Datagrid>
            <TextField source="name.en" label={"Name"} />
            <FunctionField source="active" label={"Active"} render={(r) => r.active ? 'Yes' : 'No'} />
            <TextField source="order" label={"Order"} />
            <EditButton />
        </Datagrid>
    </List>
);

export const CategoryEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <TextInput source="id" label={"ID"} disabled />
            <BooleanInput source="active" label={"Active"} helperText={"If you want to deactivate this category, please uncheck this field."} />
            <TranslatableInputs locales={['en', 'tr']}>
                <TextInput source="name" label={"Name"} fullWidth />
            </TranslatableInputs>
            <TextInput source="order" label={"Order"} fullWidth helperText={"Please enter a number to determine the order of the category."} />
            {/* <RadioButtonGroupInput source="type" label="Type" choices={choices} optionText="name" /> */}
        </SimpleForm>
    </Edit>
);

export const CategoryCreate = (props) => (
    <Create title="New" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <BooleanInput source="active" label={"Active"} disabled hidden />
            
            <TextInput source="id" label={"ID"} />

            <TranslatableInputs locales={['en', 'tr']}>
                <TextInput source="name" label={"Name"} fullWidth />
            </TranslatableInputs>

            <TextInput source="order" label={"Order"} fullWidth helperText={"Please enter a number to determine the order of the category."} />

        </SimpleForm>
    </Create>
);
