// A "dashboard" react component that is the default page for the app
// It will have boxes for each stat, starting with "Total Users" and "Total Events"
// Each box will have a big figure and a subtitle underneath
// will use material UI

import React, { useState, useEffect } from 'react';
import { Link, usePermissions } from 'react-admin';

// UI
import { Grid, Typography } from '@material-ui/core';

// Firebase
import { db } from "../database/firebase";
import { collection, getDoc, getDocs, query, orderBy, where, limit, updateDoc, doc, getCountFromServer } from "firebase/firestore";

const DashboardPage = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [stats, setStats] = useState({ users: 0, events: 0 });

    const [activeUsersLoading, setActiveUsersLoading] = useState(false);
    const [activeUsers, setActiveUsers] = useState(0);

    const { permissions } = usePermissions();

    let isAdmin = false;
    if (permissions.crole && permissions.crole === "admin") isAdmin = true;

    useEffect(() => {
        const fetchData = async () => {

            // get single doc from firestore
            // collection: stats, doc: counts
            const docRef = doc(db, "stats", "counts");
            const querySnapshot = await getDoc(docRef);
            let data = querySnapshot.data();

            const coll = collection(db, 'users');
            const q = query(coll, where('isVerified', '==', false));
            const unverifiedSnapshot = await getCountFromServer(q);

            // add to data
            data.unverifiedUsers = unverifiedSnapshot.data().count;

            if (data && data.users && data.events) {
                setStats(data);
                setLoading(false);
            } else {
                setError('Error loading stats');
                setLoading(false);
            }
        };

        try {
            fetchData();
        } catch (error) {
            console.error(error);
            setError(error.message);
            setLoading(false);
        }

    }, []);

    const computeActiveUsers = () => {
        // get users who has lastLogin field within the last 60 days, field is epoch time in seconds, but the field is a string in firestore

        setActiveUsersLoading(true);

        const dateEpochFilter = (Date.now() / 1000) - (60 * 24 * 60 * 60).toString();
        const dateEpochFilterNumber = Number(dateEpochFilter);

        const q = query(collection(db, "users"), where("lastLogin", ">", dateEpochFilterNumber.toString(), limit(1000)));

        getDocs(q).then((querySnapshot) => {
            setActiveUsers(querySnapshot.size);
        }).catch((error) => {
            console.error(error);
            setError(error.message);
        }).finally(() => {
            setActiveUsersLoading(false);
        });
    }

    if(!isAdmin) {
        return (
            <div style={{ flex: 1 }}>
                <Grid container spacing={0} style={{ background: '#FFF', padding: 30 }}>
                    <Grid item xs={12} style={{ borderBottom: '1px solid #AAA', marginBottom: 20 }}>
                        <Typography variant="h4" gutterBottom>
                            Dashboard
                        </Typography>
                    </Grid>
    
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Access Denied
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You do not have permission to access this page.
                        </Typography>
                    </Grid>
    
                </Grid>
            </div>
        );
    }


    if (error) return (
        <div style={{ flex: 1 }}>
            <Grid container spacing={0} style={{ background: '#FFF', padding: 30 }}>
                <Grid item xs={12} style={{ borderBottom: '1px solid #AAA', marginBottom: 20 }}>
                    <Typography variant="h4" gutterBottom>
                        Dashboard
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Error
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {error}
                    </Typography>
                </Grid>

            </Grid>
        </div>
    );

    return (
        <div style={{ flex: 1 }}>
            <Grid container spacing={0} style={{ background: '#FFF', padding: 30 }}>
                <Grid item xs={12} style={{ borderBottom: '1px solid #AAA', marginBottom: 20 }}>
                    <Typography variant="h4" gutterBottom>
                        Dashboard
                    </Typography>
                </Grid>


                <Grid item xs={12} sm={6} md={4} style={{ marginBottom: 10 }}>
                    <Typography variant="h6" gutterBottom>
                        Total Users
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        {loading ? 'Loading...' : stats.users.toLocaleString()}
                    </Typography>

                    <Link to="/users?filter=%7B%7D">
                        <Typography variant="subtitle1" gutterBottom>
                            View Users
                        </Typography>
                    </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h6" gutterBottom>
                        Active Users
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        {
                            activeUsersLoading ? 'Loading...' : (
                                activeUsers ? activeUsers.toLocaleString() : '-'
                            )
                        }
                    </Typography>

                    <Link to="#" onClick={() => computeActiveUsers()}>
                        <Typography variant="subtitle1" gutterBottom>
                            {
                                activeUsers ? 'last 60 days' : 'Compute'
                            }
                        </Typography>
                    </Link>

                </Grid>


                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h6" gutterBottom>
                        Total Events
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        {loading ? 'Loading...' : stats.events}
                    </Typography>

                    <Link to="/events?filter=%7B%7D">
                        <Typography variant="subtitle1" gutterBottom>
                            View Events
                        </Typography>
                    </Link>

                </Grid>

                <Grid item xs={12} sm={6} md={4} style={{ marginBottom: 10 }}>
                    <Typography variant="h6" gutterBottom>
                        Unverfied Users
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        {loading ? 'Loading...' : (
                            stats.unverifiedUsers.toLocaleString().concat(' ', 'users', ' / ', (100 * (stats.unverifiedUsers / stats.users).toString().substring(0, 6))).concat('%')
                        )}
                    </Typography>

                    <Link to="/users?filter=%7B%7D">
                        <Typography variant="subtitle1" gutterBottom>
                            View Users
                        </Typography>
                    </Link>
                </Grid>


            </Grid>
        </div>

    );

};

export default DashboardPage;