import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { useSearchParams } from 'react-router-dom'

// UI
import { Grid, Typography } from '@material-ui/core';

// Libs
import moment from "moment";

// Firebase
import { db } from "../database/firebase";
import { collection, getDoc, getDocs, query, orderBy, where, limit, updateDoc, doc, getCountFromServer } from "firebase/firestore";

// DataGrid
import { DataGridPremium, GridToolbarContainer, trTR } from '@mui/x-data-grid-premium';

// Definitions
const SEATED_COLUMNS = [
    { field: 'id', headerName: 'Product ID', width: 100, filterable: false, valueGetter: (params) => params.row.id },
    { field: 'name', headerName: 'Product Name', width: 100, filterable: false, valueGetter: (params) => params.row.name },
    { field: 'count', headerName: 'Sold', width: 100, filterable: false, valueGetter: (params) => params.row.count },
    { field: 'total', headerName: 'Total', width: 100, filterable: false, valueGetter: (params) => params.row.total, valueFormatter: (params) => params.value.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) }
];
const D_COLUMNS = [
    {
        field: 'title', headerName: 'Title', width: 200, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.category ? params.row.category.toUpperCase() : ""}</b>;
        }
    },
    {
        field: 'category', headerName: 'Category', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.category ? params.row.category.toUpperCase() : "";
        }
    },
    {
        field: 'price', headerName: 'Price', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.price ? params.row.price.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) : "-";
        }
    },
    {
        field: 'original-stock', headerName: 'Stock', width: 100, filterable: false, valueGetter: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.stock;
        }
    },

    {
        field: 'sold', headerName: 'Sold', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.sold}</b>;
            return params.row.sold ? params.row.sold : 0;
        }
    },
    {
        field: 'stock', headerName: 'Remaining', width: 100, filterable: false,
        renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.stock - (params.row.sold ? params.row.sold : 0);
        }
    },
    {
        field: 'totalSale', headerName: 'Total Sale', width: 100, filterable: false, valueGetter: (params) => {

            let totalSale = params.row.price * params.row.sold;
            if (params.row.id === 'total') totalSale = params.row.totalSale;

            return totalSale ? totalSale.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) : "-";
        }, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.value}</b>;
            return params.value;
        }
    }
];
const R_COLUMNS = [
    {
        field: 'title', headerName: 'Title', width: 200, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.category ? params.row.category.toUpperCase() : ""}</b>;
        }
    },
    {
        field: 'category', headerName: 'Category', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.category ? params.row.category.toUpperCase() : "";
        }
    },
    {
        field: 'price', headerName: 'Remaining', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            if (params.row.sold && params.row.sold != 0 && params.row.price && params.row.downPaymentAmount) {
                let remainingAmount = params.row.price - params.row.downPaymentAmount;
                return remainingAmount.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
            } else {
                return "-";
            }
        }
    },
    {
        field: 'original-stock', headerName: 'Total Stock', width: 100, filterable: false, valueGetter: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.stock + params.row.sold;
        }
    },

    {
        field: 'sold', headerName: 'Sold', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.sold}</b>;
            return params.row.sold ? params.row.sold : 0;
        }
    },
    { field: 'stock', headerName: 'Remaining Stock', width: 100, filterable: false },
    {
        field: 'totalRemaining', headerName: 'Total Remaining', width: 100, filterable: false, valueGetter: (params) => {

            let remainingAmount = params.row.price - params.row.downPaymentAmount;
            let totalRmaining = remainingAmount * params.row.sold;
            if (params.row.id === 'total') totalRmaining = params.row.totalRmainingAmount;

            return totalRmaining ? totalRmaining.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) : "-";

        }, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.value}</b>;
            return params.value;
        }
    }
];
const C_COLUMNS = [
    {
        field: 'code', headerName: 'Coupon Code', width: 200, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.code.toUpperCase()}</b>;
            return params.row.code.toUpperCase();
        }
    },
    {
        field: 'discount', headerName: 'Discount (TL)', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.discount ? params.row.discount.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) : "-";
        }
    },
    { field: 'stock', headerName: 'Stock', width: 100, filterable: false },
    {
        field: 'used', headerName: 'Used', width: 100, filterable: false, renderCell: (p) => {
            if (p.row.id === 'total') return <b>{p.row.totalUsed}</b>;
            return p.row.used ? p.row.used : 0;

        }
    },
    {
        field: 'remaining', headerName: 'Remaining', width: 100, filterable: false, renderCell: (p) => {
            if (p.row.id === 'total') return null;
            let remaining = p.row.stock - (p.row.used ? p.row.used : 0)
            if (remaining < 0) remaining = 0;
            return remaining;
        }
    },
    {
        field: 'totalDiscount', headerName: 'Total Discount (TL)', width: 150, filterable: false, renderCell: (p) => {
            if (p.row.id === 'total') return p.row.totalDiscount ? <b>{p.row.totalDiscount.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</b> : "-";
            let totalDisc = p.row.discount * (p.row.used ? p.row.used : 0);
            if (totalDisc == 0) return "-";
            else return totalDisc.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
        }
    }
];
let productCategories = ['deal', 'general', 'promotion', 'vip', 'invitation', 'extras', 'guestlist', 'reservation'];


const SaleReportPage = () => {

    const [searchParams] = useSearchParams();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [eventData, setEventData] = useState(false);
    const [stockData, setStockData] = useState(false);

    const [isSeatedEvent, setIsSeatedEvent] = useState(false);

    // summary data from sales
    const [salesData, setSalesData] = useState(false);
    const [actualSalesData, setActualSalesData] = useState({
        totalSaleAmount: 0,
        totalSuccessfullSalesCount: 0,
        totalAmountOfCashSales: 0,
        totalAmountOfCCSales: 0,
        totalSoldTickets: 0,
    });

    const [columns, setColumns] = useState(D_COLUMNS);
    const [couponsData, setCouponsData] = useState(false);
    const [couponColumns, setCouponColumns] = useState(C_COLUMNS);

    const [remainingData, setRemainingData] = useState(false);
    const [remainingColumns, setRemainingColumns] = useState(R_COLUMNS);

    // summary data
    const [totalProductSalesSummary, setTotalProductSalesSummary] = useState(0);
    const [totalCommssionAmountSummary, setTotalCommssionAmountSummary] = useState(0);
    const [totalRmainingAmountSummary, setTotalRmainingAmountSummary] = useState(0);
    const [totalCouponDiscountSummary, setTotalCouponDiscountSummary] = useState(0);
    const [subTotal, setSubTotal] = useState(0);

    const eventID = searchParams.get('eventID') ? searchParams.get('eventID') : '1';
    const stockID = searchParams.get('stockID') ? searchParams.get('stockID') : null;

    useEffect(() => {
        const fetchData = async () => {

            // fetch event and stock data
            const eventDoc = doc(db, "events-v2", eventID);
            const eventSnap = await getDoc(eventDoc);
            const event = eventSnap.data();

            if (event && event.type === 3) {
                setIsSeatedEvent(true);
            }

            setEventData(event);

            const stockDoc = doc(db, "stocks", stockID ? stockID : event.sessions[0].times[0].id);
            const stockSnap = await getDoc(stockDoc);
            const stock = stockSnap.data();
            setStockData(stock);

            let actualSalesDataT = await fetchEventSalesData(event);
            await computeSalesData(stock);
            await computeRemainingPayments();
            await computeCouponsData();
            await computeCommission(event, actualSalesDataT);

            setIsLoading(false);

        };

        try {
            fetchData();
        } catch (error) {
            console.error(error);
            setError(error.message);
        }

    }, []);


    if (!eventID) return <EmptyState title="Error" message="Event ID is missing" isError={true} />

    const fetchEventSalesData = async (event) => {

        if (!event || !event.id) return;

        const q = query(
            collection(db, "sales"),
            where("event.id", "==", event.id),
            where("status", '==', 1)
        );

        const querySnapshot = await getDocs(q);

        let salesEventS = [];
        let totalSaleAmount = 0;
        let totalSuccessfullSalesCount = 0;
        let totalAmountOfCashSales = 0;
        let totalAmountOfCCSales = 0;
        let totalSoldTickets = 0;

        querySnapshot.forEach((doc) => {
            let data = doc.data();

            salesEventS.push(data);

            totalSaleAmount += data.paymentTotal;
            totalSuccessfullSalesCount++;

            if (data.tickets && data.tickets.length && parseInt(data.tickets.length)) {
                totalSoldTickets += data.tickets.length;
            }

            // account for both cash and doorcash
            if (data.paymentType === 'cash' || data.paymentType === 'doorcash') {
                totalAmountOfCashSales += data.paymentTotal;
            } else {
                totalAmountOfCCSales += data.paymentTotal;
            }
        });

        setActualSalesData({
            totalSaleAmount: totalSaleAmount,
            totalSuccessfullSalesCount: totalSuccessfullSalesCount,
            totalAmountOfCashSales: totalAmountOfCashSales,
            totalAmountOfCCSales: totalAmountOfCCSales,
            totalSoldTickets: totalSoldTickets
        });

        return {
            totalSaleAmount: totalSaleAmount,
            totalSuccessfullSalesCount: totalSuccessfullSalesCount,
            totalAmountOfCashSales: totalAmountOfCashSales,
            totalAmountOfCCSales: totalAmountOfCCSales,
            totalSoldTickets: totalSoldTickets
        };
    }

    const computeSalesData = async (stock) => {

        let tData = [];

        productCategories.forEach((productCategory) => {

            if (stock.stock[productCategory]) {

                stock.stock[productCategory].forEach((product) => {

                    let productData = {
                        id: product.id,
                        category: productCategory,
                        title: product.title.en,
                        price: product.price,
                        stock: product.stock,
                        sold: product.sold
                    }

                    tData.push(productData);
                });
            }
        });


        // We need a total row at the end, only for sold and total sale
        let totalSold = 0;
        let totalSale = 0;
        tData.forEach((product) => {
            totalSold += product.sold;
            totalSale += product.sold * product.price;
        });

        let totalRow = {
            id: 'total',
            category: 'Total',
            title: '',
            price: '',
            stock: '',
            sold: totalSold,
            totalSale: totalSale
        }

        tData.push(totalRow);

        setSalesData(tData);
        setTotalProductSalesSummary(totalSale);
    }

    const computeRemainingPayments = async () => {
        let remainingPayments = [];

        productCategories.forEach((productCategory) => {
            if (stockData.stock && stockData.stock[productCategory] && stockData.stock[productCategory].length > 0) {
                stockData.stock[productCategory].forEach((product) => {
                    if (product.hasDownPayment) {
                        let productData = {
                            id: product.id,
                            category: productCategory,
                            title: product.title.en,
                            price: product.price,
                            stock: product.stock,
                            sold: product.sold,
                            downPaymentAmount: product.downPaymentAmount
                        }

                        remainingPayments.push(productData);
                    }
                });
            }
        });

        // We need a total row at the end, only for sold and total sale
        let totalSold = 0;
        let totalRmainingAmount = 0;
        remainingPayments.forEach((product) => {
            totalSold += product.sold;
            let remainingAmount = product.price - product.downPaymentAmount;
            totalRmainingAmount += product.sold * remainingAmount;
        });

        let totalRow = {
            id: 'total',
            category: 'Total',
            title: '',
            price: '',
            stock: '',
            sold: totalSold,
            totalRmainingAmount: totalRmainingAmount
        }

        remainingPayments.push(totalRow);
        setRemainingData(remainingPayments);
        setTotalRmainingAmountSummary(totalRmainingAmount);
    }

    const computeCouponsData = async () => {
        let couponsData = [];

        if (eventData.coupons) {
            eventData.coupons.forEach((coupon) => {
                let couponData = {
                    id: coupon.code,
                    code: coupon.code,
                    discount: coupon.discount,
                    stock: coupon.stock,
                    used: coupon.used
                }

                couponsData.push(couponData);
            });


            // We need a total row at the end, only for sold and total sale
            let totalDiscount = 0;
            let totalUsed = 0;
            couponsData.forEach((coupon) => {
                if (coupon.used) {
                    totalDiscount += (coupon.discount * coupon.used);
                    totalUsed += coupon.used ? coupon.used : 0;
                }
            });

            let totalRow = {
                id: 'total', code: 'Total', stock: '', used: '', remaining: '', discount: '', totalDiscount: totalDiscount, totalUsed: totalUsed
            }

            couponsData.push(totalRow);

            setCouponsData(couponsData);
            setTotalCouponDiscountSummary(totalDiscount);
        }
    }

    const computeCommission = async (event, actualSalesDataT) => {

        // first calculate commission
        // const totalStockSale = totalProductSalesSummary;
        const totalStockSale = actualSalesDataT.totalSaleAmount;
        const totalActualSale = actualSalesDataT.totalSaleAmount;
        const totalOrganisatorCashSales = actualSalesDataT.totalAmountOfCashSales;
        const coupons = totalCouponDiscountSummary;
        const remaining = totalRmainingAmountSummary;

        let sub_total = totalStockSale - coupons - remaining - totalOrganisatorCashSales;
        console.log('sub total', totalStockSale, coupons, remaining, totalOrganisatorCashSales);
        let commission = sub_total * (event.commissionFee / 100);

        console.log('\n------------------');
        console.log('totalActualSale', totalActualSale); // actual that got sold
        console.log('totalActualCCSales', actualSalesDataT.totalAmountOfCCSales); // actual that got sold (cc sales)
        console.log('totalActualCashSales', actualSalesDataT.totalAmountOfCashSales); // actual that got sold (cash sales)
        console.log('totalNumberOfCreatedTickets', actualSalesDataT.totalSoldTickets); // actual that got created

        console.log('\n')
        console.log('totalStockSale: ', totalStockSale);
        console.log('coupons', coupons);
        console.log('remaining', remaining);
        console.log('org. cash sales', totalOrganisatorCashSales);
        console.log('\n')

        console.log(`commission ${event.isCommissionExtra ? 'extra' : 'inc.'}`, commission);

        // let checkCalc = (
        //     (
        //         sub_total + totalOrganisatorCashSales // because we have this in our totalActualSale
        //     )
        //     ===
        //     (
        //         totalActualSale - (event.isCommissionExtra ? commission : 0)
        //     )
        // );

        setTotalCommssionAmountSummary(commission);
        setSubTotal(sub_total);


        console.log('Sub Total (No Cash Sales) === totalCCSales: ', sub_total, actualSalesData.totalAmountOfCCSales - (event.isCommissionExtra ? commission : 0));


        console.log('Sub Total (+ Cash Sales) === totalActualSale: ', sub_total + totalOrganisatorCashSales, totalActualSale - (event.isCommissionExtra ? commission : 0));



        // whoever is bigger is the diff, we need to check the diff
        let a = sub_total + totalOrganisatorCashSales;
        let b = totalActualSale - (event.isCommissionExtra ? commission : 0);
        if (a > b) {
            console.log('Diff: ', a - b);
        }
        else {
            console.log('Diff: ', b - a);
        }
    }

    if (error) return <EmptyState title="Error" message={error} isError={true} />
    if (isLoading || !eventData) return <EmptyState title="Loading" message="Please wait..." isError={false} />


    if (isSeatedEvent) return (
        (eventData && eventData.sessions) && (
            <React.Fragment>

                <Grid container spacing={0} style={{ background: '#FFF', padding: 30 }}>
                    <Grid item xs={12} style={{ borderBottom: '1px solid #AAA', marginBottom: 10 }}>
                        <Typography variant="h4" gutterBottom>
                            Sale Report / {eventData.name} - {moment(eventData.startdate.toDate()).format("LL")} / {stockData.time ? stockData.time : ""}
                        </Typography>
                    </Grid>
                </Grid>
                <SessionTimeAndAvailability event={eventData} stock={stockData} />
            </React.Fragment>
        )
    )


    return (
        <div style={{ flex: 1 }}>
            <Grid container spacing={0} style={{ background: '#FFF', padding: 30 }}>
                <Grid item xs={12} style={{ borderBottom: '1px solid #AAA', marginBottom: 10 }}>
                    <Typography variant="h4" gutterBottom>
                        Sale Report / {eventData.name} - {moment(eventData.startdate.toDate()).format("LL")} / {stockData.time ? stockData.time : ""}
                    </Typography>
                </Grid>
            </Grid>

            <div style={{ marginLeft: 30, marginRight: 30 }}>

                {/* Product Sales */}
                <div>
                    <h3>Sales</h3>
                    <DataGridPremium
                        rows={salesData}
                        density="compact"
                        initialState={{
                            columns: { columnVisibilityModel: {} },
                            pagination: { paginationModel: { pageSize: 25 } },
                        }}
                        onColumnVisibilityModelChange={(model) => {
                            setColumns(
                                columns.map((column) => ({
                                    ...column,
                                    hide: !model[column.field],
                                }))
                            );
                        }}
                        style={{ flex: 1, width: '100%' }}
                        getRowId={(row) => row.id}
                        columns={columns}
                        hideFooter={true}
                        slots={{
                            toolbar: CustomTableHeader
                        }}
                    />
                </div>


                {/* REMAINING PAYMENTS (FOR DOWN PAYMENT ENABLED PRODUCTS) */}
                <div style={{ marginTop: 50, marginBottom: 50 }}>
                    <h3>Remaining Payments</h3>
                    <DataGridPremium
                        rows={remainingData}
                        density="compact"
                        initialState={{
                            columns: { columnVisibilityModel: {} },
                            pagination: { paginationModel: { pageSize: 25 } },
                        }}
                        onColumnVisibilityModelChange={(model) => {
                            setRemainingColumns(
                                remainingColumns.map((column) => ({
                                    ...column,
                                    hide: !model[column.field],
                                }))
                            );
                        }}
                        style={{ flex: 1, width: '100%' }}
                        getRowId={(row) => row.id}
                        columns={remainingColumns}
                        hideFooter={true}
                        slots={{
                            toolbar: CustomTableHeader
                        }}
                    />
                </div>

                {/* COUPONS */}
                <div>
                    <h3>Coupons</h3>
                    <DataGridPremium
                        rows={couponsData}
                        density="compact"
                        initialState={{
                            columns: { columnVisibilityModel: {} },
                            pagination: { paginationModel: { pageSize: 25 } },
                        }}
                        onColumnVisibilityModelChange={(model) => {
                            setCouponColumns(
                                couponColumns.map((column) => ({
                                    ...column,
                                    hide: !model[column.field],
                                }))
                            );
                        }}
                        style={{ flex: 1, width: '100%' }}
                        getRowId={(row) => row.id}
                        columns={couponColumns}
                        hideFooter={true}
                        slots={{
                            toolbar: CustomTableHeader
                        }}
                    />
                </div>

                {/* SUMMARY SECTION */}
                <div>

                    {/* Right Aligned */}

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 50, marginBottom: 50, marginRight: 50 }}>

                        <div>
                            <div style={{ width: 500, fontSize: 16 }}>
                                {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Total Product Sales (Stock)</div>
                                    <div>{totalProductSalesSummary.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Total Remaining Amount</div>
                                    <div>{totalRmainingAmountSummary.toLocaleString('tr-TR', { style: 'currency', 'currency': 'TRY' })}</div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Total Coupon Discount</div>
                                    <div>{totalCouponDiscountSummary.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Organisator Sales (Cash)</div>
                                    <div>{actualSalesData.totalAmountOfCashSales.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Sub Total</div>
                                    <div><b>{(subTotal).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</b></div>
                                </div> */}

                            </div>
{/* 
                            <div style={{ width: 500, fontSize: 16, marginTop: 30 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Gişe Kıbrıs Fee (<b>{eventData.isCommissionExtra ? 'extra' : 'included'}</b> {eventData.commissionFee}%)</div>
                                    <div>{totalCommssionAmountSummary.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Total Payable</div>
                                    <div>
                                        <b>{((totalProductSalesSummary - (eventData.isCommissionExtra ? 0 : totalCommssionAmountSummary) - (totalCouponDiscountSummary + totalRmainingAmountSummary)) - actualSalesData.totalAmountOfCashSales).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</b>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>

                </div>
            </div>

        </div>

    );

};


const CustomTableHeader = () => {
    return (
        <GridToolbarContainer>
            {/* <GridToolbarColumnsButton /> */}
            {/* <GridToolbarFilterButton /> */}
            {/* <GridToolbarDensitySelector /> */}
            {/* <GridToolbarExport csvOptions={{ disableToolbarButton: true }} printOptions={{ hideToolbar: true }} excelOptions={{ fileName: `gisekibris-sales-${moment().format('LL-LT')}.xlsx` }} /> */}
        </GridToolbarContainer>
    );
}

const EmptyState = ({ title, message, isError }) => {
    return (
        <div style={{ flex: 1 }}>
            <Grid container spacing={0} style={{ background: '#FFF', padding: 30 }}>
                <Grid item xs={12} style={{ borderBottom: '1px solid #AAA', marginBottom: 20 }}>
                    <Typography variant="h4" gutterBottom>
                        {title ? title : "Sale Report"}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        {isError ? "Error" : "Message"}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {message}
                    </Typography>
                </Grid>

            </Grid>
        </div>
    )
}


const SessionTimeAndAvailability = ({ event, stock }) => {
    const dataProvider = useDataProvider();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {

        if (!event || !stock) return setError(true);

        const fetchData = async () => {

            const collectionRef = collection(db, 'sales');
            let q = query(collectionRef, orderBy('createdate', 'desc'),
                where('event.id', '==', event.id),
                // where('status', '==', 1) // requires index, so we will do this on the client side
            );
            const dataSnapshot = await getDocs(q);

            // loop through docs and add id
            let docs = [];
            dataSnapshot.forEach(doc => {
                if (doc.data().event.stockID) {
                    if (doc.data().status === 1 && doc.data().event.stockID == stock.stockID) {
                        docs.push({ ...doc.data(), id: doc.id });
                    }
                } else {
                    if (doc.data().status === 1) {
                        docs.push({ ...doc.data(), id: doc.id });
                    }
                }
            });

            setData(docs);
            setLoading(false);
        };

        fetchData();

    }, [event, stock]);

    if (loading) return <div>Loading...</div>;

    if (error) return <div>Error</div>;

    let tableData = {};

    if (data) {

        Object.values(data).map(sale => {
            if (typeof sale === 'object' && sale.seats) {
                sale.seats.forEach(seat => {

                    // tableData should have sessionEpoch first which is in sale.event.session
                    // then it should have productID which is in seats[].productID

                    // if tableData doesn't have sessionEpoch, create it

                    if (!tableData[sale.event.stockID]) {
                        tableData[sale.event.stockID] = {};
                    }

                    // Then we can increment the count in productID
                    if (!tableData[sale.event.stockID][seat.productID]) {
                        // find name of the productID and add it to the tableData
                        let product = stock.stock.find(r => r.id === seat.productID);
                        tableData[sale.event.stockID][seat.productID] = {
                            name: product.title.en,
                            count: 1,
                            total: product.price
                        };
                    } else {
                        let product = stock.stock.find(r => r.id === seat.productID);
                        tableData[sale.event.stockID][seat.productID].count = tableData[sale.event.stockID][seat.productID].count + 1;
                        tableData[sale.event.stockID][seat.productID].total = tableData[sale.event.stockID][seat.productID].total + product.price;
                    }

                });
            }
        });
    }

    // console.log(tableData);

    // convert tableData into data grid format
    let tableDataGrid = [];
    Object.keys(tableData).map(sessionEpoch => {
        Object.keys(tableData[sessionEpoch]).map(productID => {
            tableDataGrid.push({
                id: productID,
                name: tableData[sessionEpoch][productID].name,
                count: tableData[sessionEpoch][productID].count,
                total: tableData[sessionEpoch][productID].total,
            });
        });
    });


    // order by count desc
    tableDataGrid.sort((a, b) => {
        return b.count - a.count;
    });

    let totalRevenue = tableDataGrid.reduce((a, b) => a + b.total, 0);
    let totalSale = data.reduce((a, b) => a + b.paymentTotal, 0);

    return (
        <div style={{ borderBottom: '2px solid #AAA', marginLeft: 30, marginRight: 30 }}>
            <DataGridPremium
                rows={tableDataGrid}
                density="compact"
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                    pagination: { paginationModel: { pageSize: 100 } },
                }}
                hideFooter={true}
                style={{ flex: 1, width: '100%' }}
                getRowId={(row) => row.id}
                columns={SEATED_COLUMNS}
            />

            {/* Add a custom footer to display the totals */}
            <div style={{ marginTop: 20 }}>
                <div>
                    {/* <b>Total Sold: </b> {data.length.toString().concat(' Sales')} ({tableDataGrid.reduce((a, b) => a + b.count, 0).toString().concat(' Seats/Tickets')}) */}
                </div>

                <div>
                    {/* total revenue from product price */}
                    {/* <b>Total Revenue: </b> {totalRevenue.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })} */}
                </div>

                {
                    event.isCommissionExtra && (
                        <div>
                            {/* <b>Commission: </b> {event.commissionFee}% / {(totalRevenue * event.commissionFee / 100).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })} */}
                        </div>
                    )
                }
                <div>
                    {/* total sale with commission */}
                    {/* <b>Total Sale: </b> {totalSale.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })} */}
                </div>
                {
                    event.isCommissionExtra && (
                        <div>
                            {/* does total with commission match totalSale? */}
                            {/* <b>Check: </b> {totalSale == (totalRevenue * (event.commissionFee + 100) / 100) ? <span style={{ color: 'green' }}>Yes</span> : 'No'} */}
                        </div>
                    )
                }
            </div>

        </div>
    )
};

export default SaleReportPage;