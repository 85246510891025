import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, TranslatableInputs } from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Entity
const entity = "eventTags";

export const EventTagsList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Event Tags" exporter={false}>
        <Datagrid>
            <TextField source="name.en" label={"Name"} />
            <EditButton />
            {/* <DeleteButton basePath={`/${entity}`} /> */}
        </Datagrid>
    </List>
);

export const EventTagsEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <TranslatableInputs locales={['tr', 'en']}>
                <TextInput source="name" fullWidth />
            </TranslatableInputs>
        </SimpleForm>
    </Edit>
);

export const EventTagsCreate = (props) => (
    <Create title="Create" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <TranslatableInputs locales={['tr', 'en']}>
                <TextInput source="name" fullWidth />
            </TranslatableInputs>
        </SimpleForm>
    </Create>
);
