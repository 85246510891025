export const FirebaseConfig = {
    apiKey: "AIzaSyB72dFLFl-TwuyNnhphFylqZdh6o7vKs7c",
    authDomain: "gisekibris-8b11d.firebaseapp.com",
    projectId: "gisekibris-8b11d",
    // databaseURL: "https://gisekibris-8b11d-default-rtdb.europe-west1.firebasedatabase.app",
    // databaseURL: "https://gisekibris-8b11d-gisekibris-staging-rtdb.europe-west1.firebasedatabase.app",
    storageBucket: "gisekibris-8b11d.appspot.com",
    messagingSenderId: "513892003042",
    appId: "1:513892003042:web:9ea7fb290c366bab1802bb"
};
  
export const FirebaseOptions = {
    // lazyLoading: {
    //     enabled: true
    // }
}