import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// React-admin
import { usePermissions, useGetOne, Button, Link } from "react-admin";

// Services
import { db } from "../database/firebase";
import { collection, getDocs, query, orderBy, limit, where } from "firebase/firestore";

// Libs
import moment from 'moment';
import { DataGridPremium, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-premium';

// Icons
import { Refresh } from '@mui/icons-material';

const PROD_URL = 'https://gisekibris.com';

const D_LAST_X_ROWS = 100;
const D_ORDER_ON = 'createdate';
const D_ODER = 'asc';
const D_ENTITY = 'stocks';
const D_ENTITY_LABEL = 'Sessions';
const D_COLUMNS = [
    { field: 'id', headerName: 'ID', width: 100 },
    {
        field: 'event', headerName: "Event", width: 300, renderCell: (params) => {
            return (
                <DynamicCell reference="events-v2" id={params.row.event} field="name" />
            )
        }
    },
    {
        field: 'date', headerName: "Date / Time", width: 200, renderCell: (params) => {
            return (
                <span>{moment(params.row.date, 'YYYY-MM-DD').format('DD-MM-YYYY')} / {params.row.time}</span>
            )
        }
    },
    {
        field: 'sales', headerName: "Sales", width: 100, renderCell: (params) => {
            return (
                (params.row && params.row.sales) ? (
                    params.row.sales.length
                ) : 0
            )
        }
    },
    {
        field: 'isActive', headerName: "Active", width: 100, renderCell: (params) => {
            return (
                <span>{params.row.isActive ? (<span style={{ color: 'green' }}>Yes</span>) : <span style={{ color: 'red' }}>No</span>}</span>
            )
        }
    }
]

const INITIALLY_VISIBLE_COLUMNS = {
    id: false
};

const LastXROWS = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [searchParams] = useSearchParams()
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState(D_COLUMNS);
    const { permissions } = usePermissions();

    // Get eventID from URL
    const eventID = searchParams.get('eventID');

    // api ref
    const apiRef = React.useRef();

    let isManager = false;
    if (permissions.crole && permissions.crole === "organisationCompanyManager" && permissions.rid) isManager = true;
    if (permissions.crole && permissions.crole === "venueManager" && permissions.rid) isManager = true;

    const getData = async () => {

        const collectionRef = collection(db, D_ENTITY);

        // TODO: add filter by organisation or venue
        let q = null;
        if (eventID) q = query(collectionRef, orderBy(D_ORDER_ON, D_ODER), where('event', '==', eventID));
        else q = query(collectionRef, orderBy(D_ORDER_ON, D_ODER), limit(D_LAST_X_ROWS));

        const dataSnapshot = await getDocs(q);

        if (dataSnapshot) {

            let data = [];
            dataSnapshot.forEach((doc) => {
                data.push(
                    {
                        id: doc.id,
                        ...doc.data()
                    }
                );
            });

            if (isManager) {
                // remove channel from columns if the user is a manager
                let newColumns = columns.filter((column) => column.field !== 'channel');
                setColumns(newColumns);
            }

            setData(data);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (isManager && !eventID) return window.location.href = '/#/events-v2';
        else getData();
    }, [searchParams]);


    if (!permissions) return <div>Loading permissions...</div>
    if (isLoading) return <div>Loading data...</div>


    const CustomTableHeader = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                {/* <GridToolbarExport
                    csvOptions={{ disableToolbarButton: true }}
                    excelOptions={{
                       fields: [
                            'pnr', 'channel', 'userName', 'eventName', 'created', 'product', 'productKey'
                       ], 
                        // file name in the format of "OrganisationName - EventName - Tickets - DD/MM/YYYY"
                       fileName: `exported-tickets-${moment().format('DD/MM/YYYY')}`
                    }}
                    printOptions={{
                        hideToolbar: true,
                        fields: [
                            'pnr', 'channel', 'userName', 'eventName', 'created', 'product'
                        ]
                    }}
                /> */}
                {/* <RefreshButton refreshData={getData} /> */}
            </GridToolbarContainer>
        );
    }



    const getDetailPanelContent = (row) => {
        return (
            <div style={{ padding: 20, paddingTop: 0 }}>
                <h3>Sales & Reports</h3>

                {row.seatSelections && (
                    <a href={`${PROD_URL}/etkinlikler/${row.event}?mode=preview&stockID=${row.id}`} target="_blank" rel="noopener noreferrer" style={{ marginRight: 10 }}>
                        <Button variant="outlined" size="small" label="View Stock" />
                    </a>
                )}
                <Link to={`/sale-report?eventID=${row.event}&stockID=${row.id}`} style={{ marginRight: 10 }}>
                    <Button variant="outlined" size="small" label="Sale Report" />
                </Link>
                <Link to={`/sales?eventID=${row.event}&stockID=${row.id}`} style={{ marginRight: 10 }}>
                    <Button variant="outlined" size="small" label="View Sales" />
                </Link>
                <Link to={`/tickets?eventID=${row.event}&stockID=${row.id}`}>
                    <Button variant="outlined" size="small" label="View Tickets" />
                </Link>

                <h3>Actions</h3>

                {(moment(row.date).isAfter(moment())) ? (
                    <Button href={`/#/stocks/${row.id}`} variant="outlined" size="small" label={`Edit ${row.type === 3 ? 'Products' : 'Stock'}`} />
                ) : (
                    <Button href={`/#/stocks/${row.id}`} variant="outlined" size="small" label={`Edit ${row.type === 3 ? 'Products' : 'Stock'} (Past Event)`} disabled />
                )}

            </div>
        )
    }

    return (
        <div>
            <div style={{ marginLeft: 20 }}>
                <h1>{D_ENTITY_LABEL} List</h1>
                <span>This list shows sessions ordered on date/time, for the selected event.</span>
            </div>

            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', margin: 0, padding: 0, width: '100%' }}>
                <div className="container" style={{ padding: 20, width: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <DataGridPremium
                                rows={data}
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: INITIALLY_VISIBLE_COLUMNS
                                    }
                                }}
                                density="compact"
                                style={{ flex: 1, width: '100%' }}
                                getRowId={(row) => row.id}
                                columns={columns}
                                pageSize={10}
                                // hideFooter
                                slots={{
                                    toolbar: CustomTableHeader
                                }}


                                apiRef={apiRef}
                                onRowClick={(params) => {
                                    // show detail panel
                                    apiRef.current.toggleDetailPanel(params.id);

                                }}
                                getDetailPanelContent={({ row }) => getDetailPanelContent(row)}
                                getDetailPanelHeight={({ row }) => 'auto'} // Height based on the content.
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

const RefreshButton = ({ refreshData }) => {
    return (
        <button
            style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: 'black',
                cursor: 'pointer',
                fontSize: 10,
                fontWeight: 500,
                outline: 'none',
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center'
            }}
            onClick={refreshData}
        >
            {/* refresh icon */}
            <Refresh style={{ fontSize: 16, marginRight: 5 }} />
            REFRESH
        </button>
    );
};

const DynamicCell = ({ reference, id, field, suffix, renderFunction }) => {
    const { data, isLoading, error } = useGetOne(
        reference,
        { id }
    );

    if (isLoading) return <span>Loading...</span>
    if (error) return <span>Error</span>

    if (!field) {
        if (renderFunction) return <span>{renderFunction(data)}</span>
        else return <span>{data}</span>
    }

    if (field.indexOf('+') > -1) {
        let fields = field.split('+');
        return <span>{data[fields[0]]} {data[fields[1]]}</span>
    }
    else {
        if (renderFunction) {
            // console.log('renderFunction', renderFunction)
            // console.log('data[field]', data[field])
            return <span>{renderFunction(data[field])}</span>
        }
        else return <span>{data[field]}{suffix}</span>
    }
}

export default LastXROWS;