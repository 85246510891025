import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, TranslatableInputs, required, DateField, ImageInput, ImageField, DeleteButton, DateInput } from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Libs
import { RichTextInput } from 'ra-input-rich-text';

// Entity
const entity = "news";

export const NewsList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />}>
        <Datagrid>
            <TextField source="title.en" label={"Title (English)"} />
            <DateField source="createdate" label={"Created"} />
            <DateField source="lastupdate" label={"Updated"} />
            <EditButton />
            <DeleteButton basePath={`/${entity}`} />
        </Datagrid>
    </List>
);

export const NewsEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <h3>Banner</h3>
            <ImageInput source="banner" label="News Banner" accept="image/*" defaultValue={null}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <h3>Date</h3>
            <DateInput source="date" label="Date" validate={required()} fullWidth />
            <h3>Content</h3>
            <TranslatableInputs locales={['tr', 'en']}>
                <TextInput source="title" label={"Title"} fullWidth />
                <TextInput source="subtitle" label={"Sub Title"} fullWidth />
                <RichTextInput source="content" label="Content" validate={required()} fullWidth />
            </TranslatableInputs>
        </SimpleForm>
    </Edit>
);

export const NewsCreate = (props) => (
    <Create title="New" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <h3>Banner</h3>
            <ImageInput source="banner" label="News Banner" accept="image/*" defaultValue={null}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <h3>Date</h3>
            <DateInput source="date" label="Date" validate={required()} fullWidth />
            <h3>Content</h3>
            <TextInput source="slug" label={"URL Slug"} fullWidth />
            <TranslatableInputs locales={['tr', 'en']}>
                <TextInput source="title" label={"Title"} fullWidth />
                <TextInput source="subtitle" label={"Sub Title"} fullWidth />
                <RichTextInput source="content" label="Content" validate={required()} fullWidth />
            </TranslatableInputs>
        </SimpleForm>
    </Create>
);
