import * as React from "react";
import {
    List, Datagrid, TextField, FunctionField, ReferenceField
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Libs
import moment from "moment";

// Entity
const entity = "eventLogs";

export const EventLogList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} noCreate />} sort={{field: 'eventActionTime', order: 'DESC'}} perPage={50}>
        <Datagrid>
            {/* <TextField source="id" /> */}
            <TextField source="eventName" label="Event Name" />
            <FunctionField source="eventActionTime" label="Date Time" render={r => moment(r.eventActionTime, 'x').format('LL, LT')} />
            <TextField source={"actionBy"} label="User" />
            <TextField source={"eventAction"} label="Action" />
        </Datagrid>
    </List>
);
