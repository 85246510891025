
import * as React from "react";
import { List, Datagrid, Edit, SimpleForm, TextField, EditButton, TextInput,
    ReferenceField, BooleanField, BooleanInput } from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Entity
const entity = "affiliateForm";

export const AffiliateFormList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Affiliate Applications" exporter={false}>
        <Datagrid>
            <TextField source="name" label={"Application Name"} />
            <TextField source="surname" label={"Application Surname"} />
            <ReferenceField source="user" reference="users" label="User">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="user" reference="users" label="User Mobile">
                <TextField source="mobile" />
            </ReferenceField>
            <BooleanField source="isChecked" label="Checked" />
            <EditButton />
        </Datagrid>
    </List>
);

export const AffiliateFormEdit = (props) => {
    return (
        <Edit title="Edit" {...props}>
            <SimpleForm redirect={`/${entity}`}>

                <h2>Actions</h2>
                <BooleanInput source="isChecked" label={"Checked"} />
                <TextInput source={'notes'} label={"Notes"} fullWidth multiline rows={3} />

                <h2>Details</h2>
                <TextInput source="name" fullWidth disabled />
                <TextInput source="surname" fullWidth disabled />
                <TextInput source="facebook" fullWidth disabled />
                <TextInput source="instagram" fullWidth disabled />
                <TextInput source="twitter" fullWidth disabled />
                <TextInput source="tiktok" fullWidth disabled />
            </SimpleForm>
        </Edit>
    );
}
