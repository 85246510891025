import { useGetList } from "react-admin";

const useCategories = () => {
    const { data, isLoading, error } = useGetList('categories', {
        pagination: { page: 1, perPage: 100 }, // Adjust if needed
        sort: { field: 'name', order: 'ASC' },
    });

    if (isLoading || error) {
        return [];
    }

    return data.map(category => ({
        id: category.id, // Database ID
        label: category.name?.en || category.name, // Multilingual support
    }));
};

export default useCategories;