import * as React from "react";
import {
    List, Datagrid, ReferenceField, FunctionField, ReferenceInput, SelectInput, Link,
    Show, SimpleShowLayout, TextField, usePermissions, AutocompleteInput
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Libs
import moment from "moment";
import * as XLSX from 'xlsx-js-style';
import _ from 'lodash';

// Utils
import { truncateString } from '../utils/misc';

// Entity
const entity = "payments";

const filters = [
    <ReferenceInput source="event.id" reference="events-v2" label="Event Filter" sort={{ field: 'name', order: 'ASC' }}>
        <AutocompleteInput optionText={r => `${r.name} - ${moment(r.startdate).format('DD-MM-YYYY')}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="Event Name" style={{ width: 299 }} />
    </ReferenceInput>,
    <ReferenceInput source="user" reference="users" label="User Filter" sort={{ field: 'name', order: 'ASC' }}>
        <AutocompleteInput optionText={r => `${r.name} ${r.surname}`} matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} label="User Name" />
    </ReferenceInput>,
    // <ReferenceArrayInput source="user" reference="users" sort={{ field: 'name', order: 'ASC' }}>
    //     <AutocompleteArrayInput optionText="email" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ email: searchText })} optionValue={"id"} />
    // </ReferenceArrayInput>,
    <SelectInput source="status" label="Payment Status" choices={[
        { id: 0, name: 'Awaiting' }, { id: 1, name: 'Confirmed' }, { id: 2, name: 'Decliend' }
    ]} />
]

const exporter = (records, _getMany, dataProvider) => {

    // Add a big font title to the sheet
    let title = [
        { v: `GiseKibris CC Payments Report (1000 Record) - ${moment().format('LL-LT')}`, s: { font: { bold: true, sz: 20 } } },
    ];

    const dataSheet = XLSX.utils.aoa_to_sheet([title, [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]]);

    let headers = [
        { v: 'User', s: { font: { bold: true } } },
        { v: 'Mobile #', s: { font: { bold: true } } },
        { v: 'Event', s: { font: { bold: true } } },
        { v: 'Venue', s: { font: { bold: true } } },
        { v: 'Payment Date', s: { font: { bold: true } } },
        { v: 'Payment Method', s: { font: { bold: true } } },
        { v: 'Transaction ID', s: { font: { bold: true } } },
        { v: 'X ID', s: { font: { bold: true } } },
        { v: 'Total (₺)', s: { font: { bold: true } } },
        { v: 'Status', s: { font: { bold: true }, alignment: { horizontal: 'center' } } },
    ];

    XLSX.utils.sheet_add_aoa(dataSheet, [headers], { origin: -1 });

    // gather relationship ids
    const userIds = records.map((record) => record.user ?? null);
    const u_userIds = _.uniq(userIds);

    const venueIds = records.map((record) => record.event.venue ?? null);
    const u_venueIds = _.uniq(venueIds);

    dataProvider.getMany('venues', { ids: u_venueIds }).then(venues => {
        dataProvider.getMany('users', { ids: u_userIds }).then(users => {
            // User, Event, Venue, Pdate, pmethod, pdetails, total, status
            const data = records.map(record => {
                const userIndex = users.data && users.data.length > 0 ? users.data.findIndex(v => v.id === record.user) : -1;
                const venueIndex = venues.data && venues.data.length > 0 ? venues.data.findIndex(v => v.id === record.event.venue) : -1;


                let recordF = [
                    {
                        v: userIndex > -1 ? `${users.data[userIndex].name} ${users.data[userIndex].surname}` : '-',
                        t: 's',
                    },
                    {
                        v: userIndex > -1 ? users.data[userIndex].mobile : '-',
                        t: 's',
                    },
                    {
                        v: record.event.name,
                        t: 's',
                    },
                    venueIndex > -1 ? venues.data[venueIndex].name : '-',
                    moment(record.createdate).format("DD/MM/YY HH:mm"),
                    record.transaction.type === 'cc' ? "Credit Card" : record.transaction.type,
                    record.transaction.transid && record.transaction.transid.length > 0 ? record.transaction.transid : "N/A",
                    record.transaction.xid && record.transaction.xid.length > 0 ? record.transaction.xid : "N/A",
                    {
                        v: record.total ? record.total : '-',
                        t: record.total ? 'n' : 's',
                        s: {
                            alignment: { horizontal: "left" },
                        }
                    },
                    {
                        v: record.status === 1 ? 'Confirmed' : (record.status === 2 ? 'Declined' : 'Awaiting'),
                        s: {
                            alignment: { horizontal: "center" },
                            font: { bold: true, color: { rgb: "FFFFFF" } },
                            fill: {
                                fgColor: {
                                    rgb: record.status === 1 ? '008000' : (record.status === 2 ? 'FF0000' : 'FFA500')
                                }
                            }
                        },
                    }
                ];

                /* Write data starting at A2 */
                return XLSX.utils.sheet_add_aoa(dataSheet, [recordF], { origin: -1 });

            });

            var dataSheetCols = [
                { wch: 20 },
                { wch: 15 },
                { wch: 30 },
                { wch: 20 },
                { wch: 20 },
                { wch: 18 },
                { wch: 20 },
                { wch: 20 },
                { wch: 10 },
                { wch: 15 },
            ];

            dataSheet['!cols'] = dataSheetCols;

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataSheet, 'CC Payments');
            return XLSX.writeFile(wb, `gisekibris-cc-payments-${moment().format('LL-LT')}.xlsx`);
        });
    });
}

export const PaymentList = (props) => {
    const { permissions } = usePermissions();

    if (!permissions) return;

    let filter = {};
    if (permissions.crole && permissions.crole === "organisationCompanyManager" && permissions.rid) {
        filter = {
            event: {
                organisationCompanies: permissions.rid
            }
        }
    }
    if (permissions.crole && permissions.crole === "venueManager" && permissions.rid) {
        filter = {
            event: {
                venue: permissions.rid
            }
        }
    }
    return (
        <List {...props} filter={filter}
            exporter={exporter}
            sort={{ field: 'createdate', order: 'DESC' }}
            empty={<EmptyList entity={entity} noCreate />} hasCreate={false} filters={filters}>
            <Datagrid bulkActionButtons={false}>
                <ReferenceField source="user" reference="users">
                    <FunctionField source="name" render={(r) => `${r.name} ${r.surname}`} />
                </ReferenceField>
                <ReferenceField source="user" reference="users" label="User Mobile">
                    <TextField source="mobile" />
                </ReferenceField>

                <FunctionField source="id" render={(r) => {
                    return (r.event && r.event.session) ? (
                        <ReferenceField source="event.id" reference="sessionEvents" label="Event" link="show">
                            <FunctionField source="startdate" render={(r) => `${truncateString(r.name, 25)}`} />
                        </ReferenceField>
                    ) : (
                        <ReferenceField source="event.id" reference="events-v2" label="Event" link="show">
                            <FunctionField source="startdate" render={(r) => `${truncateString(r.name, 25)} (${r.startdate ? moment(r.startdate.seconds, 'X').format('DD/MM/YY LT') : ''})`} />
                        </ReferenceField>
                    )
                }} label="Event" />

                <FunctionField source="createdate" render={(r) => `${moment(r.createdate, 'X').format('DD/MM/YY LT')}`} label="Date Time" />
                <FunctionField source="id" render={(r) => <Link to={`/payments/${r.id}/show`}>See Details</Link>} label="Transaction Details" />
                {/* <FunctionField source="id" render={(r) => moment(r.createdate, 'X').isAfter(moment("1665033242", 'X')) ? 'New' : 'Old'} label="New/Old" /> */}
                <FunctionField source="total" render={(r) => `${r.total} TL`} />
                <FunctionField source="status" render={(r) => {
                    let bgColor = 'orange';
                    let status = 'Awaiting';
                    if (r.status === 1) {
                        bgColor = 'green';
                        status = 'Confirmed';
                    }
                    if (r.status === 2) {
                        bgColor = 'red';
                        status = 'Declined';
                    }

                    return (
                        <div style={{ backgroundColor: bgColor, textAlign: 'center', borderRadius: 5, padding: 3 }}>
                            <span style={{ color: 'white', textAlign: 'center' }}>{status}</span>
                        </div>
                    )
                }} />
            </Datagrid>
        </List >
    );
}

export const PaymentShow = () => {
    return (
        <Show title={"Payment Transaction Details"}>
            <SimpleShowLayout>
                <FunctionField source="total" label="Payment Amount" render={r => `${r.total} TL`} />
                <FunctionField source="createdate" label="Payment Date" render={r => moment(r.createdate).format('LL LT')} />
                <TextField source="transaction.cardname" label="Card Holder" />
                <TextField source="transaction.MaskedPan" label="Card Number" />
                <TextField source="transaction.orderid" label="Order ID" />
                <TextField source="transaction.transid" label="Transaction ID" />
                <TextField source="transaction.xid" label="X ID" />
                <TextField source="transaction.errmsg" label="Error Message" />
                <TextField source="transaction.mderrormessage" label="Information" />
            </SimpleShowLayout>
        </Show>
    )
}