import * as React from "react";
import { List, Datagrid, Edit, SimpleForm, TextField, EditButton, TextInput, required, NumberInput } from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Entity
const entity = "systemParameters";
const entityTR = "parametre";

export const SystemParameterList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} entityTR={entityTR} />} exporter={false} title="Sistem Parametreleri" filter={{ id: 'system' }}>
        <Datagrid>
            <TextField source="id" label={"ID"} />
            <EditButton />
        </Datagrid>
    </List>
);

export const SystemParameterEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm>

            <div style={{ display: 'none' }}>
                <TextInput source="id" label={"ID"} disabled />
            </div>

            <h3>Sistem Ayarları</h3>
            <NumberInput source="settings.adminPhone" label={"Admin Telefon Numarası"} fullWidth validate={required()} />

            <h3>Değişkenler</h3>
            <NumberInput source="variables.defaultServiceFee" label={"Varsayılan Hizmet Ücreti"} fullWidth validate={required()} />
            <NumberInput source="variables.defaultCommissionFee" label={"Varsayılan Komisyon Ücreti"} fullWidth validate={required()} />

        </SimpleForm>
    </Edit>
);
