import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput,
    ReferenceInput, SelectInput, required, SearchInput,
    ReferenceArrayField, SingleFieldList, ChipField, usePermissions, ImageInput, ImageField, TranslatableInputs,
    useEditContext
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";
import InputRow from "../components/form/InputRow";
import SelectUserByEmailInput from "../components/form/SelectUserByEmailInput";

// Libs
import moment from "moment";
import jsonExport from 'jsonexport/dist';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import { RichTextInput } from 'ra-input-rich-text';

// Entity
const entity = "organisationCompanies";

const filters = [
    <SearchInput source="name" />,
    <ReferenceInput source="city" reference="cities">
        <SelectInput optionText={"name.en"} fullWidth />
    </ReferenceInput>
]

const exporter = async (records, _getMany, dataProvider) => {

    // gather relationship ids
    const userIds = records.map((record) => record.managers[0] ?? null);
    const u_userIds = _.uniq(userIds);

    const users = await dataProvider.getMany('users', { ids: u_userIds });
    const data = [];

    for (const record of records) {
        const userIndex = users.data && users.data.length > 0 ? users.data.findIndex(v => v.id === record.managers[0]) : -1;
        const events = await dataProvider.getList('events', { pagination: { page: 1, perPage: 100 }, filter: { organisationCompanies: record.id } });
        data.push({
            "Name": record.name,
            "City": record.city,
            "Phone": record.phone,
            "Email": record.email,
            "Total Events": events.data.length,
            "Manager": userIndex > -1 ? `${users.data[userIndex].name} ${users.data[userIndex].surname}` : '-',
            "Manager Phone": userIndex > -1 ? `${users.data[userIndex].mobile}` : '-',
        });
    }

    return jsonExport(data, (err, csvString) => {
        const arrayOfArrayCsv = csvString.split("\n").map((row) => {
            return row.split(",")
        });

        const wb = XLSX.utils.book_new();
        const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
        XLSX.utils.book_append_sheet(wb, newWs);

        return XLSX.writeFileXLSX(wb, `gisekibris-organisation-companies-${moment().format('LL-LT')}.xlsx`);
    });
}

export const OrganisationCompanyList = (props) => {
    const { permissions } = usePermissions();

    if (!permissions) return;

    let filter = {};
    if (permissions.crole && permissions.crole === "organisationCompanyManager" && permissions.rid) {
        filter = { id: permissions.rid }
    }
    return (
        <List {...props} filter={filter}
            exporter={exporter}
            empty={<EmptyList entity={entity} />} title="Organisation Companies" filters={filters} 
            hasCreate={permissions.crole && (permissions.crole === 'admin' || permissions.crole === 'operator')}>
            <Datagrid bulkActionButtons={false}>
                <TextField source="name" label={"Name"} />
                <ReferenceArrayField source="managers" reference="users" label="Managers">
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <EditButton />
            </Datagrid>
        </List>
    );
}

export const OrganisationCompanyCreate = (props) => (
    <Create title="New" {...props} redirect={"list"}>
        <SimpleForm redirect={`/${entity}`}>

            <h3>Company Details</h3>
            <TextInput source="name" label={"Name"} fullWidth validate={required()} />
            <TextInput source="slug" label={"URL Slug"} fullWidth validate={required()} />
            <TextInput source="phone" label={"Phone"} fullWidth validate={required()} />
            <TextInput source="email" label={"Email"} fullWidth validate={required()} />

            <h4>About Company</h4>
            <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                <RichTextInput source="about" multiline rows={5} label="About" fullWidth validate={required()} />
            </TranslatableInputs>

            <h4>Media</h4>
            <InputRow>
                <div>
                    <span style={{ color: 'red', fontSize: 12, marginTop: -10 }}>Square ratio images should be uploaded. Both sides should be equal.</span>
                    <ImageInput source="logo" label="Logo" accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>
                </div>
                <div>
                    <span style={{ color: 'red', fontSize: 12, marginTop: -10 }}>Landscape ratio images should be uploaded. 16:9 ratio is preferrable.</span>
                    <ImageInput source="banner" label="Banner" accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>
                </div>
            </InputRow>

            <h3>Address</h3>
            <InputRow>
                <ReferenceInput source="city" reference="cities">
                    <SelectInput optionText={"name.en"} validate={required()} fullWidth />
                </ReferenceInput>
                <TextInput source="address" label={"Full Address"} fullWidth defaultValue={null} />
            </InputRow>

            <h3>Legal</h3>
            <InputRow>
                <SelectInput source="company.type" choices={[
                    { id: 'K', name: 'KK' },
                    { id: 'M', name: 'MŞ' },
                    { id: 'Y', name: 'YŞ' },
                    { id: 'R', name: 'RK' },
                ]} fullWidth validate={required()} />
                <TextInput source="company.name" label={"Legal Entity"} fullWidth validate={required()} />
                <TextInput source="company.taxName" label={"Legal Tax Name"} fullWidth validate={required()} />
            </InputRow>
            <ImageInput source="taxDocument" label="Tax Document" accept="image/*" defaultValue={null}>
                <ImageField source="src" />
            </ImageInput>

            <h3>Financial</h3>
            <TextInput source="bank.companyName" fullWidth label="Bank Account Name" defaultValue={null} />
            <InputRow>
                <TextInput source="bank.name" fullWidth label="Bank Name" defaultValue={null} />
                <TextInput source="bank.accountNo" fullWidth label="Bank IBAN Number" defaultValue={null} />
            </InputRow>

            <h3>Managers</h3>
            <SelectUserByEmailInput source={"managers"} multiple={true} required={false} />

        </SimpleForm>
    </Create>
);


export const OrganisationCompanyEdit = (props) => {
    const { permissions } = usePermissions();
    if (!permissions) return;

    return (
        <Edit title="Edit" {...props}>
            <SimpleForm redirect={`/${entity}`}>
                <EditForm permissions={permissions} />
            </SimpleForm>
        </Edit>
    );
}
const EditForm = ({ permissions }) => {
    const { record, isLoading } = useEditContext();
    if (isLoading) return null;

    return (
        <React.Fragment>
            <h3>Company Details</h3>
            <TextInput source="name" label={"Name"} fullWidth validate={required()} />
            <TextInput source="slug" label={"URL Slug"} fullWidth validate={required()} />
            <TextInput source="phone" label={"Phone"} fullWidth validate={required()} />
            <TextInput source="email" label={"Email"} fullWidth validate={required()} />

            <h4>About Company</h4>
            <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                <RichTextInput source="about" multiline rows={5} label="About" fullWidth validate={required()} />
            </TranslatableInputs>

            <h4>Media</h4>
            <InputRow>
                <div>
                    <span style={{ color: 'red', fontSize: 12, marginTop: -10 }}>Square ratio images should be uploaded. Both sides should be equal.</span>
                    <ImageInput source="logo" label="Logo" accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>
                </div>
                <div>
                    <span style={{ color: 'red', fontSize: 12, marginTop: -10 }}>Landscape ratio images should be uploaded. 16:9 ratio is preferrable.</span>
                    <ImageInput source="banner" label="Banner" accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>
                </div>
            </InputRow>

            <h3>Address</h3>
            <InputRow>
                <ReferenceInput source="city" reference="cities">
                    <SelectInput optionText={"name.en"} validate={required()} fullWidth />
                </ReferenceInput>
                <TextInput source="address" label={"Full Address"} fullWidth defaultValue={null} />
            </InputRow>

            <h3>Legal</h3>
            <InputRow>
                <SelectInput source="company.type" choices={[
                    { id: 'K', name: 'KK' },
                    { id: 'M', name: 'MŞ' },
                    { id: 'Y', name: 'YŞ' },
                    { id: 'R', name: 'RK' },
                ]} fullWidth validate={required()} />
                <TextInput source="company.name" label={"Legal Entity"} fullWidth validate={required()} />
                <TextInput source="company.taxName" label={"Legal Tax Name"} fullWidth validate={required()} />
            </InputRow>
            <ImageInput source="taxDocument" label="Tax Document" accept="image/*" defaultValue={null}>
                <ImageField source="src" />
            </ImageInput>

            <h3>Financial Details</h3>
            <TextInput source="bank.companyName" fullWidth label="Bank Account Name" defaultValue={null} />
            <InputRow>
                <TextInput source="bank.name" fullWidth defaultValue={null} />
                <TextInput source="bank.accountNo" label="Bank IBAN Number" fullWidth defaultValue={null} />
            </InputRow>

            <h3>Managers</h3>
            <SelectUserByEmailInput source={"managers"} multiple={true} record={record} disabled={permissions.crole && permissions.crole !== 'admin'} required={false} />
            
        </React.Fragment>
    )
}