import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput,
    ReferenceArrayInput, SelectArrayInput
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Entity
const entity = "featureds";

export const FeaturedList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} exporter={false}>
        <Datagrid>
            <TextField source="id" label={"ID"} />
            <EditButton />
        </Datagrid>
    </List>
);

export const FeaturedEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <TextInput source="id" label={"ID"} disabled />
            <ReferenceArrayInput source="venues" reference="venues" label="Venues" sort={{ field: 'name', order: 'ASC' }} defaultValue={[]} perPage={150}>
            <SelectArrayInput optionText="name" fullWidth
                    matchSuggestion={() => true}
                    filterToQuery={searchText => ({ name: searchText })}
                    defaultValue={[]}
                />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);

export const FeaturedCreate = (props) => (
    <Create title="New" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <TextInput source="id" label={"ID"} />
            <ReferenceArrayInput source="venues" reference="venues" label="Venues" sort={{ field: 'name', order: 'ASC' }} defaultValue={[]} perPage={150}>
                <SelectArrayInput optionText="name" fullWidth
                    matchSuggestion={() => true}
                    filterToQuery={searchText => ({ name: searchText })}
                    defaultValue={[]}
                />
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);
