import * as React from "react";
import { useInput } from 'react-admin';
import { useFormContext } from "react-hook-form";

// Firebase
import { db } from "../../database/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useNotify } from "react-admin";

const SelectUserByEmailInput = ({ source, multiple, record, required, disabled }) => {

    const { register, setValue } = useFormContext();
    const notify = useNotify();

    // Here we need to filter the users by email
    // First we get the input, then we filter the users by email
    // Then display a list of users with their email and name
    // When the user selects a user, we need to set the "user" to the selected user id to the form data

    const [searchedUsers, setSearchedUsers] = React.useState([]);
    const [queryEmail, setQueryEmail] = React.useState('');
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [hasSearched, setHasSearched] = React.useState(false);


    React.useEffect(() => {

        // register the input as required
        register(source.toString(), { required: required });

        if (record && record[source]) {
            // here we only have the IDs of the users, so let's fetch the users and normalise our data
            fetchPreSelectedUsers();
        }
    }, [record]);

    const fetchPreSelectedUsers = async () => {
        let ids = multiple ? record[source] : [record[source]];
        const q = query(collection(db, "users"), where("id", "in", ids));
        const querySnapshot = await getDocs(q);

        const data = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
        });

        setSelectedUsers(data);
    }

    const handleUserSelection = (user) => {
        if (multiple) {
            setSelectedUsers([...selectedUsers, user]);
        } else {
            setSelectedUsers([user]);
        }

        setQueryEmail('');
        setHasSearched(false);
        setSearchedUsers([]);

        // Set the value to the form data
        setValue(source, multiple ? [...selectedUsers, user].map(u => u.id) : user.id, { shouldDirty: true });
    }

    const handleUserSearch = async (searchText) => {

        // first check if the user is already selected
        if (selectedUsers && selectedUsers.length > 0) {
            const user = selectedUsers.find(u => u.email === searchText);
            if (user) {
                return notify('User already selected', 'info');
            }
        }

        const q = query(collection(db, "users"), where("email", "==", searchText));
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
        });

        setHasSearched(true);
        setSearchedUsers(data);
    }

    return (
        <div style={{ width: '100%' }}>

            {
                !disabled && (
                    (selectedUsers && selectedUsers.length > 0) ? (
                        (multiple) ? <SearchInput queryEmail={queryEmail} setQueryEmail={setQueryEmail} handleUserSearch={handleUserSearch} /> : null
                    ) : (
                        <SearchInput queryEmail={queryEmail} setQueryEmail={setQueryEmail} handleUserSearch={handleUserSearch} />
                    )
                )
            }

            {
                (searchedUsers && searchedUsers.length > 0) && (
                    <React.Fragment>
                        <h4>Search Result: </h4>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginBottom: '10px'
                            }}>
                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                {searchedUsers.map((user, i) => {
                                    return <SearchResultUserCard key={i} user={user} handleUserSelection={handleUserSelection} multiple={multiple} />
                                })}
                            </ul>
                        </div>
                    </React.Fragment>
                )
            }

            {
                hasSearched && searchedUsers && Array.isArray(searchedUsers) && (searchedUsers.length == 0) && (
                    <p>No users found. Please try with a different email address.</p>
                )
            }

            <div>
                {(selectedUsers && selectedUsers.length > 0) && (
                    <React.Fragment>
                        <h4>Chosen User(s): </h4>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginBottom: '10px'
                            }}>
                            {selectedUsers.map((user, i) => {
                                return <SelectedUserCard key={i} user={user} removeUser={() => {
                                    const updatedUsers = selectedUsers.filter(u => u.id !== user.id);
                                    setSelectedUsers(updatedUsers.filter(u => u.id !== user.id));
                                    const updatedUsersIDs = updatedUsers.map(u => u.id);
                                    multiple ? setValue(source, updatedUsersIDs, { shouldDirty: true }) : setValue(source, updatedUsersIDs[0], { shouldDirty: true });
                                }} disabled={disabled} />
                            })}
                        </div>
                    </React.Fragment>
                )}

            </div>

        </div >
    );
}


const SearchInput = ({ queryEmail, setQueryEmail, handleUserSearch }) => {
    return (
        <div style={{ display: 'flex', marginBottom: '10px', flexDirection: 'row', justifyContent: 'flex-start', width: '100%' }}>
            <input
                style={{
                    width: '100%',
                    padding: '5px',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    marginRight: 10
                }}
                value={queryEmail}
                placeholder="Search by email"
                onChange={(e) => setQueryEmail(e.target.value)}
                type="text" />
            <button
                style={{
                    width: '100%',
                    padding: '5px',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    flex: 1,
                    minWidth: 100
                }}
                onClick={(e) => {
                    e.preventDefault();
                    handleUserSearch(queryEmail);
                }}>Search</button>
        </div>
    )
}

const SearchResultUserCard = ({ user, handleUserSelection, key, multiple }) => {
    return (
        <li
            key={key}
            style={{
                padding: '8px',
                border: '1px solid #ccc',
                marginBottom: '5px',
                borderRadius: '5px',
                cursor: 'pointer',
            }}
            onClick={(e) => {
                e.preventDefault();
                handleUserSelection(user);
            }}
        >
            {user.email}<br />
            {user.name} {user.surname}<br />
            Role: {user.crole ? user.crole : 'N/A'}

            <br /><br />

            <button style={{
                width: '100%', padding: '5px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc',
                backgroundColor: 'rgb(156, 39, 176)',
                color: 'white',
                marginTop: 10
            }}>
                {
                    multiple ? 'Add' : 'Select'
                }
            </button>

        </li>
    )
}

const SelectedUserCard = ({ user, removeUser, disabled }) => {
    return (
        <div
            style={{
                padding: '8px',
                border: '1px solid #ccc',
                marginBottom: '5px',
                borderRadius: '5px',
                cursor: 'pointer',
                marginRight: 10
            }}>

            {user.email}<br />
            {user.name} {user.surname}<br />
            Role: {user.crole ? user.crole : 'N/A'}

            <br />

            {
                !disabled && (
                    <button
                        style={{
                            padding: '5px',
                            height: 30,
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            backgroundColor: '#FF6961',
                            color: 'white',
                            marginTop: 10
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            removeUser(user);
                        }}>
                        Remove
                    </button>
                )
            }
        </div>
    )
}

export default SelectUserByEmailInput;