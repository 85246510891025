import React from "react";
import {
    Edit,
    required, FormDataConsumer, ArrayInput, SimpleFormIterator, NumberInput,
    TextInput, RadioButtonGroupInput, TranslatableInputs, BooleanInput, usePermissions, useEditContext,
    SimpleForm,
    FunctionField
} from 'react-admin';

// Libs
import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import moment from "moment";

// Components
import NoDeleteToolbar from '../../components/form/NoDeleteToolbar';

// UI
import { Box } from '@mui/material';

// Constants
const ticketGroupStatuses = [{ id: 0, name: "On Sale" }, { id: 1, name: "Sold Out" }, { id: 2, name: "Hidden" }];

/**
 * 
 * @returns Event V2 Edit
 */

export const StockEdit = (props) => {
    const { permissions } = usePermissions();
    if (!permissions) return;

    return (
        <Edit title="Edit Stock" {...props} redirect={"/events-v2"}>
            <SimpleForm redirect={`/events-v2`} toolbar={<NoDeleteToolbar />}>
                <EditStockForm permissions={permissions} />
            </SimpleForm>
        </Edit>
    );
}
const EditStockForm = ({ permissions }, ...props) => {
    const { record } = useEditContext();

    return (
        <React.Fragment>
            {(!record.seatSelections) ? <StandardProductFormComponent stock={record} /> : <SeatedProductFormComponent stock={record} />}
        </React.Fragment>
    )
}

// FORM COMPONENTS
const StandardProductFormComponent = ({ stock }) => {

    return (
        <React.Fragment>
            <h2>Details</h2>
            <div style={{fontSize: 14}}>
                <div>
                    <span><b>Stock ID:&nbsp;</b></span><FunctionField source="id" label={"Stock ID"} fullWidth disabled render={(record) => record.id} fontSize={14}/>
                </div>
                <div>
                    <span><b>Date / Time:&nbsp;</b></span><FunctionField source="date" label={"Date"} fullWidth disabled render={record => {
                        return moment(record.date).format("DD/MM/YYYY").concat(" - ").concat(record.time);
                    }} fontSize={14} />
                </div>
            </div>

            <h2>Products / Stocks</h2>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: 20 }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <div style={{ border: '1px dashed #000', padding: 5 }}>
                        <h3>Special Deal <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                        <ArrayInput source="stock.deal" defaultValue={[]}>
                            <SimpleFormIterator disableRemove disableAdd={stock.type == 3}>
                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return standardProductForm(getSource, false, scopedFormData, true);
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </div>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <div style={{ border: '1px dashed #000', padding: 5 }}>
                        <h3>Reservation <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                        <ArrayInput source="stock.guestlist" defaultValue={[]}>
                            <SimpleFormIterator disableRemove disableAdd={stock.type == 3}>
                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return standardProductForm(getSource, false, scopedFormData);
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </div>
                </Box>
            </Box>

            <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: 20 }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <div style={{ border: '1px dashed #000', padding: 5 }}>
                        <h3>General <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                        <ArrayInput source="stock.general" defaultValue={[]}>
                            <SimpleFormIterator disableRemove disableAdd={stock.type == 3}>
                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return standardProductForm(getSource, false, scopedFormData, true);
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </div>

                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <div style={{ border: '1px dashed #000', padding: 5 }}>
                        <h3>VIP List <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                        <ArrayInput source="stock.vip" defaultValue={[]}>
                            <SimpleFormIterator disableRemove disableAdd={stock.type == 3}>
                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return standardProductForm(getSource, false, scopedFormData, true);
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </div>
                </Box>
            </Box>

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <div style={{ border: '1px dashed #000', padding: 5 }}>
                        <h3>Extras <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                        <ArrayInput source="stock.extras" defaultValue={[]}>
                            <SimpleFormIterator disableRemove disableAdd={stock.type == 3}>
                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return standardProductForm(getSource, false, scopedFormData, true);
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </div>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <div style={{ border: '1px dashed #000', padding: 5 }}>
                        <h3>Invitations <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(* all fields required)</span></h3>
                        <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Can create invitations for guests to attend the event.</span>
                        <ArrayInput source="stock.invitation" defaultValue={[]}>
                            <SimpleFormIterator disableRemove disableAdd={stock.type == 3}>
                                <FormDataConsumer>
                                    {({ formData, scopedFormData, getSource, ...rest }) => {
                                        return standardProductForm(getSource, false, scopedFormData, true);
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </div>
                </Box>
            </Box>
        </React.Fragment>
    );
}
const SeatedProductFormComponent = ({ stock }) => {
    return (
        <ArrayInput source="stock" defaultValue={[]}>
            <SimpleFormIterator disableReordering disableRemove className="sessionEventTicketsArray" disableAdd={stock.type == 3}>
                <FormDataConsumer>
                    {({ formData, scopedFormData, getSource, ...rest }) => {
                        return seatedProductForm(getSource, false, scopedFormData);
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    )
}

// PRODUCT FORMS
const seatedProductForm = (getSource, showSold, scopedFormData) => {
    return (
        <React.Fragment>
            {/* Auto generate ticket ID on add */}
            <TextInput source={getSource("id")} label={"Ticket ID"} validate={required()} fullWidth disabled defaultValue={genereateTicketID()} />

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TranslatableInputs locales={['tr', 'en']} className="translatableInputST" defaultLocale="tr">
                        <TextInput source={getSource("title")} label={"Title"} validate={required()} fullWidth autoComplete="off" />
                    </TranslatableInputs>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TranslatableInputs locales={['tr', 'en']} defaultLocale="tr">
                        <TextInput source={getSource("description")} label={"Description"} validate={required()} fullWidth multiline rows={3} />
                    </TranslatableInputs>
                </Box>
            </Box>
            <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("price")} type="number" label={"Price"} validate={required()} fullWidth />
            <BooleanInput source={getSource('isPromotionAvailable')} label={"Promotion Available?"} fullWidth />


            {scopedFormData && scopedFormData.isPromotionAvailable && (
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TranslatableInputs locales={['tr', 'en']} defaultLocale="tr">
                            <TextInput source={getSource("promotion.label")} label={"Promotion Label"} fullWidth />
                        </TranslatableInputs>
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("promotion.price")} type="number" label={"Promotion Price"} fullWidth />
                    </Box>
                </Box>
            )}

            {/* {showSold && <NumberInput source={getSource("sold")} type="number" label={"Total Sold"} disabled fullWidth />} */}
        </React.Fragment>
    )
}
const standardProductForm = (getSource, showSold, scopedFormData, isEditForm) => {
    return (
        <React.Fragment>

            {/* Auto generate ticket ID on add */}
            <TextInput source={getSource("id")} label={"Ticket ID"} validate={required()} fullWidth disabled defaultValue={genereateTicketID()}/>

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source={getSource("title.tr")} label={"Turkish Title"} validate={required()} fullWidth className="autoCapitalize" autoComplete="off" />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source={getSource("title.en")} label={"English Title"} validate={required()} fullWidth className="autoCapitalize" autoComplete="off" />
                </Box>
            </Box>
            <Box>
                {/* <TextInput source={getSource("description.tr")} label={"Turkish Description"} validate={required()} fullWidth multiline rows={3} /> */}
                <RichTextInput source={getSource("description.tr")} label="Turkish Description" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />
                {/* <TextInput source={getSource("description.en")} label={"English Description"} validate={required()} fullWidth multiline rows={3} /> */}
                <RichTextInput source={getSource("description.en")} label="English Description" validate={required()} fullWidth multiline rows={3} toolbar={<RichTextInputToolbar size="small" />} />
            </Box>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <NumberInput
                        onWheel={event => { event.target.blur(); }} source={getSource("price")} type="number" label={"Price"} min={0}
                        validate={required()} fullWidth disabled={isEditForm && scopedFormData.sold && scopedFormData.sold > 0}
                        helperText={isEditForm ? 'If you need to change the price of a product, you can do so by creating a new product and hiding the old one.' : ''}
                    />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("stock")} type="number" label={"Stock"} validate={required()} fullWidth />
                </Box>
            </Box>
            {true && <NumberInput source={getSource("sold")} type="number" label={"Total Sold (For this session)"} disabled fullWidth />}

            <BooleanInput source={getSource("hasDownPayment")} label={"Has Down Payment?"} defaultValue={false} />

            {
                scopedFormData.hasDownPayment && (
                    <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("downPaymentAmount")} type="number" label={"Down Payment Price"} validate={required()} min={0} fullWidth />
                )
            }

            <BooleanInput source={getSource("hasBasketLimit")} label={"Has Basket Limit?"} defaultValue={false} />

            {
                scopedFormData.hasBasketLimit && (
                    <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("basketLimit")} type="number" label={"Basket Limit"} validate={required()} fullWidth />
                )
            }

            <RadioButtonGroupInput source={getSource("status")} label={"Status"} choices={ticketGroupStatuses} defaultValue={0} validate={required()} />
        </React.Fragment>
    )
}

// UTILS
const genereateTicketID = () => {
    // Capital letters and numbers, length 8
    return Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substring(0, 8).toUpperCase();
}