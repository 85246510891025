const InputRow = props => {
    const sm = window.innerWidth > 768;
    return (
        <div style={{ display: 'flex', flexDirection: sm ? 'row' : 'column', width: '100%', marginTop: props.mt ?? 0 }}>
            {
                props.children && (
                    props.children.map((child, index) => (
                        <div key={index} style={{ flex: 1, marginRight: '1rem' }}>
                            {child}
                        </div>
                    ))
                )
            }
        </div>
    )
}

export default InputRow;