import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput,
    ReferenceInput, SelectInput, ImageInput, ImageField, required, ReferenceField, SearchInput,
    TranslatableInputs, ReferenceArrayInput, usePermissions, SelectArrayInput,
    ReferenceArrayField, SingleFieldList, ChipField, useEditContext
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";
import InputRow from '../components/form/InputRow';
import SelectUserByEmailInput from "../components/form/SelectUserByEmailInput";

// Libs
import { RichTextInput } from 'ra-input-rich-text';
import moment from "moment";
import jsonExport from 'jsonexport/dist';
import * as XLSX from 'xlsx';
import _ from 'lodash';

const entityMultiple = "venues";

const filters = [
    <SearchInput source="name" />,
    <ReferenceInput source="city" reference="cities">
        <SelectInput optionText={"name.en"} fullWidth />
    </ReferenceInput>,
    <ReferenceInput source="category" reference="venueCategories">
        <SelectInput optionText={"name.en"} fullWidth />
    </ReferenceInput>,
]

const exporter = async (records, _getMany, dataProvider) => {

    // gather relationship ids
    let userIds = [];
    records.map((record) => (record.managers && record.managers.length > 0 && record.managers[0] != null) && userIds.push(record.managers[0]));
    const u_userIds = _.uniq(userIds);

    // Gather category IDs
    const categoryIDs = records.map((record) => record.categories[0] ?? null);
    const u_categoryIDs = _.uniq(categoryIDs);

    const users = u_userIds.length > 0 ? await dataProvider.getMany('users', { ids: u_userIds }) : { data: [] };
    const categories = await dataProvider.getMany('venueCategories', { ids: u_categoryIDs });
    const data = [];

    for (const record of records) {
        const userIndex = users.data && users.data.length > 0 ? users.data.findIndex(v => v.id === record.managers[0]) : -1;
        const categoryIndex = categories.data && categories.data.length > 0 ? categories.data.findIndex(v => v.id === record.categories[0]) : -1;
        const events = await dataProvider.getList('events', { pagination: { page: 1, perPage: 100 }, filter: { venue: record.id } });

        data.push({
            "Name": record.name,
            "City": record.city,
            "Category": categoryIndex > -1 ? categories.data[categoryIndex].name.en : '-',
            "Phone": record.phone ?? '-',
            "Email": record.email && record.email.length > 0 ? record.email : '-',
            "Total Events": events.data.length,
            "Manager": userIndex > -1 ? `${users.data[userIndex].name} ${users.data[userIndex].surname}` : '-',
            "Manager Phone": userIndex > -1 ? `${users.data[userIndex].mobile}` : '-',
        });
    }

    return jsonExport(data, (err, csvString) => {
        const arrayOfArrayCsv = csvString.split("\n").map((row) => {
            return row.split(",")
        });

        const wb = XLSX.utils.book_new();
        const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
        XLSX.utils.book_append_sheet(wb, newWs);

        return XLSX.writeFileXLSX(wb, `gisekibris-venues-${moment().format('LL-LT')}.xlsx`);
    });
}

export const VenueList = (props) => {
    const { permissions } = usePermissions();

    if (!permissions) return;

    let filter = {};
    if (permissions.crole && permissions.crole === "venueManager" && permissions.rid) {
        filter = { id: permissions.rid }
    }

    if(permissions.crole && permissions.crole !== "admin" && permissions.crole !== "operator" && !permissions.rid) return <EmptyList customMessage="You don't the right permission set to view this page. Please contact GişeKıbrıs if you believe this is an error." noCreate={true} />;
    
    return (
        <List {...props}
            exporter={exporter}
            filter={filter} empty={<EmptyList entity={entityMultiple} />} filters={filters}
            hasCreate={permissions.crole && (permissions.crole === 'admin' || permissions.crole === 'operator')}>
            <Datagrid bulkActionButtons={false}>
                <TextField source="name" />
                <ReferenceArrayField source="categories" reference="venueCategories" label="Categories">
                    <SingleFieldList linkType={false}>
                        <ChipField source="name.en" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField source="managers" reference="users" label="Managers">
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceField source="city" reference="cities" link={false}>
                    <TextField source="name.en" />
                </ReferenceField>
                <EditButton basePath={`/${entityMultiple}`} />
            </Datagrid>
        </List>
    );
}

export const VenueCreate = (props) => (
    <Create title="Create" {...props}>
        <SimpleForm redirect={`/${entityMultiple}`}>

            <h4>Basic Information</h4>
            <InputRow>
                <TextInput source="name" fullWidth validate={required()} />
                <TextInput source="slug" label="URL Slug" fullWidth validate={required()} />
            </InputRow>

            <ReferenceArrayInput source="categories" reference="venueCategories">
                <SelectArrayInput optionText={"name.en"} fullWidth validate={required()} />
            </ReferenceArrayInput>

            <h4>Media</h4>
            <InputRow>
                <div>
                    <span style={{ color: 'red', fontSize: 12, marginTop: -10 }}>Square ratio images should be uploaded. Both sides should be equal.</span>
                    <ImageInput source="logo" label="Logo" accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>
                </div>
                <div>
                    <span style={{ color: 'red', fontSize: 12, marginTop: -10 }}>Landscape ratio images should be uploaded. 16:9 ratio is preferrable.</span>
                    <ImageInput source="banner" label="Banner" accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>
                </div>
            </InputRow>

            <h4>Venue Layout</h4>
            <span style={{ color: 'red', fontSize: 12, marginTop: -10 }}>Square ratio images should be uploaded. Both sides should be equal.</span>
            <ImageInput source="layout" label="Venue Layout" accept="image/*" defaultValue={null}>
                <ImageField source="src" />
            </ImageInput>

            <h4>Youtube</h4>
            <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Please note that the entry should only contain the ID (the code after ?v=) of the video NOT the whole URL.</span>
            <TextInput source="youtube" label="Video ID (Youtube ID)" fullWidth defaultValue={null} />

            <h4>Contact</h4>
            <InputRow>
                <TextInput source="phone" fullWidth defaultValue={null} />
                <TextInput source="email" fullWidth defaultValue={null} />
            </InputRow>

            <h4>About Venue</h4>
            <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                <RichTextInput source="about" multiline rows={5} label="About" fullWidth validate={required()} />
            </TranslatableInputs>

            <h4>Address Information</h4>
            <InputRow>
                <ReferenceInput source="city" reference="cities">
                    <SelectInput optionText={"name.en"} fullWidth validate={required()} />
                </ReferenceInput>
                <TextInput source="address" fullWidth validate={required()} />
            </InputRow>
            <TextInput source="coordinates" fullWidth defaultValue={null} />

            <h4>Company Details</h4>
            <InputRow>
                <TextInput source="company.name" fullWidth validate={required()} />
                <TextInput source="company.bank" fullWidth defaultValue={null} />
                <TextInput source="company.accountNo" fullWidth defaultValue={null} />
            </InputRow>

            <h4>SEO Related</h4>
            <TextInput source="google.keywords" fullWidth defaultValue={null} />
            <TextInput source="google.description" fullWidth defaultValue={null} />

            <h3>Managers</h3>
            <SelectUserByEmailInput source={"managers"} multiple={true} />

        </SimpleForm>
    </Create>
);

export const VenueEdit = (props) => {
    const { permissions } = usePermissions();
    if (!permissions) return;

    return (
        <Edit title="Edit" {...props}>
            <SimpleForm redirect={`/${entityMultiple}`}>
                <EditForm permissions={permissions} />
            </SimpleForm>
        </Edit>
    );
}
const EditForm = ({ permissions }) => {
    const { record, isLoading } = useEditContext();
    if (isLoading) return null;
    return (
        <React.Fragment>
            <h4>Basic Information</h4>
            <InputRow>
                <TextInput source="name" fullWidth validate={required()} />
                <TextInput source="slug" label="URL Slug" fullWidth validate={required()} />
            </InputRow>

            <ReferenceArrayInput source="categories" reference="venueCategories">
                <SelectArrayInput optionText={"name.en"} fullWidth validate={required()} />
            </ReferenceArrayInput>

            <h4>Media</h4>
            <InputRow>
                <div>
                    <span style={{ color: 'red', fontSize: 12, marginTop: -10 }}>Square ratio images should be uploaded. Both sides should be equal.</span>
                    <ImageInput source="logo" label="Logo" accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>
                </div>
                <div>
                    <span style={{ color: 'red', fontSize: 12, marginTop: -10 }}>Landscape ratio images should be uploaded. 16:9 ratio is preferrable.</span>
                    <ImageInput source="banner" label="Banner" accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>
                </div>
            </InputRow>

            <h4>Venue Layout</h4>
            <span style={{ color: 'red', fontSize: 12, marginTop: -10 }}>Square ratio images should be uploaded. Both sides should be equal.</span>
            <ImageInput source="layout" label="Venue Layout" accept="image/*" defaultValue={null}>
                <ImageField source="src" />
            </ImageInput>

            <h4>Youtube</h4>
            <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Please note that the entry should only contain the ID (the code after ?v=) of the video NOT the whole URL.</span>
            <TextInput source="youtube" label="Video ID (Youtube ID)" fullWidth defaultValue={null} />

            <h4>Contact</h4>
            <InputRow>
                <TextInput source="phone" fullWidth defaultValue={null} />
                <TextInput source="email" fullWidth defaultValue={null} />
            </InputRow>

            <h4>About Venue</h4>
            <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                <RichTextInput source="about" multiline rows={5} label="About" fullWidth validate={required()} />
            </TranslatableInputs>

            <h4>Address Information</h4>
            <InputRow>
                <ReferenceInput source="city" reference="cities">
                    <SelectInput optionText={"name.en"} fullWidth validate={required()} />
                </ReferenceInput>
                <TextInput source="address" fullWidth validate={required()} />
            </InputRow>
            <TextInput source="coordinates" fullWidth defaultValue={null} />

            <h4>Company Details</h4>
            <InputRow>
                <TextInput source="company.name" fullWidth validate={required()} />
                <TextInput source="company.bank" fullWidth defaultValue={null} />
                <TextInput source="company.accountNo" fullWidth defaultValue={null} />
            </InputRow>

            <h4>SEO Related</h4>
            <TextInput source="google.keywords" fullWidth defaultValue={null} />
            <TextInput source="google.description" fullWidth defaultValue={null} />

            <h3>Managers</h3>
            <SelectUserByEmailInput source={"managers"} multiple={true} record={record} disabled={permissions.crole && permissions.crole !== 'admin'} />

        </React.Fragment>
    )
}