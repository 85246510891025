import { defaultTheme } from 'react-admin';
// import { purple } from '@mui/material/colors';

const theme = {
    ...defaultTheme,
    sidebar: {
        width: 170,
        closedWidth: 50,
    },
    palette: {
        primary: {
            main: '#000',
        },
    },
    components: {
        ...defaultTheme.components,
        // RaDatagrid: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: "Lavender",
        //             "& .RaDatagrid-headerCell": {
        //                 backgroundColor: "MistyRose",
        //             },
        //         }
        //     }
        // }
    }
};

export default theme;