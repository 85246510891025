import { CreateButton } from 'react-admin'
import EmptyStateImage from '../../assets/empty-state.png';

const EmptyList = props => (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: 600, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
        <div>
            {/* image from  */}
            <img src={EmptyStateImage} alt="Empty State" style={{ width: 250 }} />
        </div>
        <div style={{ fontSize: 20, marginTop: 20 }}>
            {props.mode && props.mode === 'loading' ? 'Loading...' : 'Oops!'}
        </div>
        <div style={{ fontSize: 14, marginTop: 15 }}>
            {props.customMessage ? props.customMessage :
                props.mode && props.mode === 'loading' ? 'Please wait a moment.' : 'No data to display.'
            }
        </div>
        {
            (props.mode && props.mode === 'loading') ?
                (
                    null
                ) : (
                    (!props.noCreate) && (
                        <div style={{ marginTop: 20 }}>
                            <CreateButton />
                        </div>
                    )
                )
        }
    </div>
);

export default EmptyList;