import * as React from "react";
import { List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput, TranslatableInputs, required } from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";

// Libs
import { RichTextInput } from 'ra-input-rich-text';

// Entity
const entity = "pages";

export const PageList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} noCreate />} hasCreate={false}>
        <Datagrid>
            <TextField source="title.en" label={"Title (English)"} />
            <EditButton />
        </Datagrid>
    </List>
);

export const PageEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <TextInput source="id" label={"ID"} disabled />
            {/* <div style={{minHeight: 500, height: 500}}> */}
                <TranslatableInputs locales={['tr', 'en']}>
                    <TextInput source="title" label={"Title"} fullWidth />
                    <RichTextInput source="content" label="Content" validate={required()} fullWidth />
                </TranslatableInputs>
            {/* </div> */}
        </SimpleForm>
    </Edit>
);

export const PageCreate = (props) => (
    <Create title="New" {...props}>
        <SimpleForm redirect={`/${entity}`}>
            <TextInput source="id" label={"ID"} />
            <TranslatableInputs locales={['tr', 'en']}>
                <TextInput source="title" label={"Title"} fullWidth />
                <RichTextInput source="content" label="Content" validate={required()} fullWidth />
            </TranslatableInputs>
        </SimpleForm>
    </Create>
);
