import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, FunctionField, EditButton,
    ReferenceField, TextInput, useEditContext
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";
import SelectUserByEmailInput from "../components/form/SelectUserByEmailInput";

// Entity
const entity = "saleAgents";

export const SaleAgentList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} title="Sale Agents">
        <Datagrid>
            <ReferenceField source="user" reference="users" label="User" linkType="show">
                <FunctionField render={(r) => `${r.name} ${r.surname}`} />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
);

export const SaleAgentCreate = (props) => (
    <Create title="New" {...props} redirect="list">
        <SimpleForm redirect={`/${entity}`}>
            <h3>User Selection</h3>
            <SelectUserByEmailInput source={"user"} required={true} />

            <h3>Details</h3>
            <TextInput source={'notes'} label={"Notes"} fullWidth multiline rows={3} />
        </SimpleForm>
    </Create>
);

export const SaleAgentEdit = (props) => (
    <Edit title="Edit" {...props} redirect="list">
        <SimpleForm redirect={`/${entity}`}>
            <EditForm />
        </SimpleForm>
    </Edit>
);
const EditForm = (props) => {
    const { record, isLoading } = useEditContext();
    if (isLoading) return null;

    return (
        <React.Fragment>
            <h3>User</h3>
            <SelectUserByEmailInput source={"user"} required={true} record={record} />

            <h3>Details</h3>
            <TextInput source={'notes'} label={"Notes"} fullWidth multiline rows={3} />
        </React.Fragment>
    )
}