import { Layout } from 'react-admin';

// Components
import CustomMenu from './CustomMenu';

const CustomLayout = props => (
    <Layout
        {...props}
        color="#AE256D"
        menu={CustomMenu}
    />
)

export default CustomLayout;