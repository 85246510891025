import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
    apiKey: "AIzaSyB72dFLFl-TwuyNnhphFylqZdh6o7vKs7c",
    authDomain: "gisekibris-8b11d.firebaseapp.com",
    // databaseURL: "https://gisekibris-8b11d-default-rtdb.europe-west1.firebasedatabase.app",
    // databaseURL: "https://gisekibris-8b11d-gisekibris-staging-rtdb.europe-west1.firebasedatabase.app",
    projectId: "gisekibris-8b11d",
    storageBucket: "gisekibris-8b11d.appspot.com",
    messagingSenderId: "513892003042",
    appId: "1:513892003042:web:9ea7fb290c366bab1802bb"
};

// let apps = getApps();
let app = initializeApp(firebaseConfig);

// if (!apps.length) {
// }

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);


export {
    db
}